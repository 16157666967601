
.text-input{ border:1px solid #4DA4CF; padding:2px; }
.text-input:focus{ border:1px solid #FFD100; padding:2px; }

.float_bar a{ color:#375E93; }
.float_bar a:hover{ color:#09C; text-decoration:none; }
.float_bar{
    border-radius:4px;
    background-color: #E0EAEF;
    float:right;
    margin-top:6px;
    padding:5px 8px;
}

.bar{
    border-radius: 4px;
    background-color: #E0EAEF;
    padding:5px 10px;
}

.ajaxlink:hover{ color:#0099CC; border-bottom:dashed 1px #0099CC; }
.ajaxlink, .MultiFile-remove{
    text-decoration:none;
    border-bottom:dashed 1px #375E93;
    color:#375E93;
}

.hinttext{
    color:#5F98BF;
}

.pathway {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: #d5d5d5 dashed thin;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 14px;
  a { color:#D1292C; &:hover { color:#D1292C; text-decoration:underline; } }
}

.module {
  .moduletitle{
    margin: 0;
    padding: 20px 0;
    line-height: 1;
    border-bottom: #d5d5d5 solid thin;
    text-transform: uppercase;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 24px;
    color:#222222;
  }
}

span.fast_cfg_link { padding:4px; }
span.fast_cfg_link img{ border:none; }

/**************************** Сообщения сессии ********************************/

.sess_messages {
    padding:10px;
    background:#E0EAEF;
    margin-bottom:15px;
    display:block;
    clear:both;
    border-radius:4px;
}

.sess_messages div{
    padding-left:20px;
    line-height:16px;
    margin-bottom:2px;
}

.sess_messages .message_info {
    background:url(../images/icons/info.png) no-repeat left center;
}
.sess_messages .message_success {
    background:url(../images/icons/yes.png) no-repeat left center;
    color: green;
}
.sess_messages .message_error {
    background:url(../images/icons/no.png) no-repeat left center;
    color: red;
}

/*------------------------ MODULE "LATEST PHOTO" -----------------------------*/
.mod_lp_titlelink{
	font-size:14px;
	font-weight:bold;
	margin-bottom:5px;
}
.photo_thumb .mod_lp_albumlink a{
	color:#666666;
	font-size:12px;
}
.mod_lp_photo{
	padding-right:10px;
}
.mod_lp_details, .photo_thumb .mod_lp_albumlink .mod_lp_details a {
	font-size:10px;
}
.mod_lp_details img{
	width:15px;
}

/*--------------------------- MODULE "POLLS" ---------------------------------*/
.mod_poll_title {
	margin-bottom: 5px;
}
.mod_poll_submit {
	margin-top: 10px;
}
.mod_poll_gauge {
	background-color:#09C;
	display: table;
	height: 8px;
	margin-bottom: 8px;
}
.search_field {
	width: 100%;
	border: 1px solid #666666;
}
.search_row1 {
	background-color:#FFFFFF;
}
.search_row2 {
	background-color:#EBEBEB;
}

/*------------------------ MODULE "AUTHORIZATION" ----------------------------*/
.authtable input#login,
.authtable input#pass{
    width:200px;
    border:solid 1px #4DA4CF;
    padding:2px;
}

#login_btn{
    font-size:16px;
    width:100px;
}
/*----------------------- MODULE "CONTENT CATS" ---------------------------*/
ul.mod_cat_list {
    list-style: none outside none;
    margin: 0 0 5px 0;
    overflow: hidden;
    padding:0 0 0 16px;
	clear:both;
}
ul.mod_cat_list ul {
    margin-left: 0;
    padding-left: 16px;
	list-style: none outside none;
}
ul.mod_cat_list li {
	margin:2px 0;
}
ul.mod_cat_list li a.folder, ul.mod_cat_list li.folder, div.float_bar a.folder {
	background:url("../images/icons/folder.png") no-repeat scroll 0 0 transparent;
	padding:0 5px 0 20px;
}
ul.mod_cat_list li a.cat_plus, ul.mod_cat_list li a.cat_minus {
    display: block;
    float: left;
    height: 16px;
    width: 16px;
	margin: 0 0 0 -16px;
}
ul.mod_cat_list li a.cat_plus {
    background: url("../images/icons/cat_plus.png") no-repeat scroll left 2px transparent;
}
ul.mod_cat_list li a.cat_minus {
    background: url("../images/icons/cat_minus.png") no-repeat scroll left 2px transparent;
}
/*----------------------- MODULE "LATEST ARTICLES" ---------------------------*/
.mod_latest_entry{
	margin-bottom:10px;
	clear:both;
}
.mod_latest_title{
	font-size:14px;
}
.mod_latest_blog_title{
	font-size:16px;
}
.mod_latest_date{
	margin-bottom:10px;
    padding:2px 0px;
	font-size:11px;
	color:gray;
}
.mod_latest_date a{
	color:#5F98BF;
}
.mod_latest_desc{
	margin-bottom:5px;
}
.mod_latest_rating{
    padding:1px;
	background:url(../images/icons/rating.png) no-repeat left center;
    padding-left:20px;
}
.mod_latest_comments{
    padding:1px;
	background:url(../images/icons/comment-small.png) no-repeat left center;
    padding-left:20px;
}
.mod_latest_hits{
    padding:1px;
	background:url(../images/icons/eye.png) no-repeat left center;
    padding-left:20px;
}
.mod_latest_rss{
    clear:both;
	padding-left:20px;
	background:url(../images/icons/rss.png) no-repeat left center;
	height:16px;
	line-height:16px;
	text-decoration:underline;
	color:#333;
}

.mod_latest_rss a{
	text-decoration: underline;
	color: #333;
}

.mod_latest_image{
    float:left;margin-right:10px;margin-bottom:15px;
    background:#FFF;
    padding:1px;
    border:solid 1px #6E93C9;
}

.mod_latest_pagebar .pagebar{
    margin-top:25px !important;
    margin-bottom:10px !important;
}

.mod_latest_pagebar .pagebar .pagebar_title{
    margin-left:0px;
}

/*-------------------------- REGISTRATION FORM -------------------------------*/
.regrow1{
	background-color:#EBEBEB;
}
.regrow2{
	background-color:#FFFFFF;
}
.regstar{
	font-size:18px;
	color:#FF0000;
}

#regform strong{
    font-size:16px;
    color:#375E93;
}

#regform small{
    color:#5F98BF;
}

/*------------------------------- LOGIN FORM ---------------------------------*/

.lf_notice{
    font-size:14px;
    margin-bottom:10px;
}

.lf_title{ font-size:18px; color:#1873AA; }

.lf_field{ margin-bottom: 10px; }

.lf_field input {
    width:250px; padding:3px; padding-left:20px; font-size:16px; border:solid 1px #4DA4CF; }

.lf_field input:focus {
    border:solid 1px #FFD100;
}

.lf_submit{
    margin-top:10px;
}

.lf_submit input{
    font-size:16px;
}

.login_form #remember{
    margin:0px;
    padding:0px;
}

.login_form #login_field{ background:url(../images/icons/user.png) no-repeat 2px 5px; }
.login_form #pass_field{ background:url(../images/icons/key.png) no-repeat 2px 5px; }

/*------------------------------ PAGES BAR -----------------------------------*/
.pagebar{
  margin: 25px 0;
  font-family: 'Roboto-Regular', sans-serif;
  font-size: 16px;
	clear:both;
  .pagebar_title{
    margin:5px;
  }
  .pagebar_page{
    margin:5px;
    padding:5px 10px;
    color: #000;
  }
  .pagebar_current{
    margin:5px;
    padding:5px 10px;
    border: #d5d5d5 solid thin;
    color: #D1292C;
  }
}

/*----------------------------- SITE SEARCH ----------------------------------*/
.search_match{
	background-color:#66CCFF;
	color:#FFF;
    border-radius:2px;
	padding:0 2px;
}
.search_date{
	float:right;
	height:14px;
	font-size:10px;
	color: #5F98BF;
	margin:4px 4px 0 0;
}
#from_search{
	display:none;
	margin:10px 0 0 0;
	border-top:1px #4DA4CF solid;
	position:relative;
}
#from_search label{
	color: #666;
}
#from_search label.selected{
	color: #000;
}
.price_search {
	width: 60%;
	border: 1px solid #666666;
}

.search_input {
	width: 300px;
	border:solid 1px gray;
}
.search_block {
	margin:0 0 7px 0;
	clear:both;
	padding:6px 10px;
	clear:both;
	overflow:hidden;
}
.search_block:hover {
	background: #E8FFDB;
}
.search_result_title {
	font-size:16px;
	font-weight:bold;
}
.search_result_title span {
	width:20px;
	display:block;
	float:left;
}
.search_result_desc {
	margin:4px 0 0 20px; clear:both;
}
.search_result_desc img {
	margin: 2px 10px 0 0;
    width: 70px;
    float: left;
}
.search_result_desc p {
	color:#666;
}
.search_result_desc a {
	color:#666;
}
.search_result_link {
    margin: 5px 0 0 0;
}
/*-------------------------------- FORMS -------------------------------------*/
.userform_fieldtitle{ font-size: 14px; }
.userform_fieldtitle .mustbe { font-size: 16px; color: #CC0000; }
/*------------------------- COMPONENT "CONTENT" ------------------------------*/
.component {
    padding:20px;
    margin-bottom:20px;
    background: #FFF;
    border-radius:4px;
    box-shadow:0 2px 2px #333;
    overflow:hidden;
}
.contentlist {
	clear:both;
}
.con_heading {
  margin: 0 0 25px;
  padding: 20px 0;
  line-height: 1;
  border-bottom: #d5d5d5 solid thin;
  text-transform: uppercase;
  font-family: 'RobotoCondensed-Bold', sans-serif;
	font-size: 24px;
	color:#222222;
  &.simple {
    text-transform: none;
  }
}
h3 {
	font-size: 18px;
    font-weight:normal;
	color:#375E93;
    margin: 15px 0px;
}
.con_pubdate{
	color:#666666;
	font-weight:bold;
}
.con_description {
	margin-top:     2px;
	margin-bottom:  15px;
}
.con_description p{
    margin-bottom:  4px;
}
.con_desc{
	margin-top:0px;
}
.con_text{
    margin:15px 0px;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
}
.con_subcat{
    font-size:18px;
    padding-left:23px;
    background: url(../images/icons/folder.png) no-repeat left center;
    height:18px;
    line-height:18px;
}
.con_title{
	font-weight:bold;
	font-size:16px;
    margin-bottom:10px;
}
.con_icons{
	margin:4px;
	margin-top:9px;
	margin-left:10px;
}
.con_icon{
	margin-top:4px;
}
.con_details{
    margin-bottom:10px;
	color:#999999;
}

.con_image {
  margin: 0 0 30px;
  img { max-width: 100%; }
}
/*------------------------- COMPONENT "PHOTOS" -------------------------------*/
.photo_thumb img, .photo_layout img {
    width: 100%;
}
.photo_layout{
    margin-bottom: 15px;
}
.photo_thumb{
	display:block;
    border:solid 1px #C3D6DF;
    padding:15px;
    border-radius:4px;
    margin-right:5px;
    margin-bottom:5px;
}
.photo_thumb a{
    font-size:14px;
}
.photo_bar{
	background-color: #EBEBEB;
	margin:6px 0px 6px 0px;
}
.photo_desc{
	margin-bottom:10px;
}
.photo_box{
	border:solid 1px silver;
}
.photo_date_td{
	background-color:#EBEBEB;
}
.photo_subcat{
    font-size:16px;
}
.photo_gallery{
    margin-bottom:15px;
    clear:both;
}
.photo_userbar{
	padding:10px;
	background-color:#EBEBEB;
	margin-bottom:2px;
}
.photo_toolbar table{
    height:30px;
}

.photo_toolbar a{
	text-decoration:underline;
    font-size:14px;
    color:#375E93;
}
.photo_add_link{
    padding-left:20px;
    background:url(../images/icons/addphoto.png) no-repeat left center;
    font-size:14px;
	margin: 0 0 0 8px;
}
.photo_bbinput{
	width:200px;
	padding:2px;
    border:solid 1px #4DA4CF;
}
.photo_row{
	height:auto !important;
	overflow:hidden;
	padding:0.8em 0;
	width:99%;
}
.photo_album_tumb{
    height:100%;
    float:left;
    position:relative;
    width:200px;
    margin-right:15px;
    margin-bottom:15px;
    border:solid 1px #C3D6DF;
    padding:15px;
    border-radius:4px;
}

.photo_container{
    text-align:center !important;
}
.photo_album_img{
    background-color:#FFFFFF;
    background-image:none;
    border:1px solid #C0C0C0;
    margin:0 0.3em 0.3em 0;
    padding:3px;
    vertical-align:bottom;
}
.photo_txt{
    text-align:center !important;
}
.photo_txt .photo_album_title{
    font-size:16px;
    height: 25px;
    display:block;
}
.photo_container_today{
	color:#09F;
	font-weight:bold;
	position:absolute;
	top: 40px;
	left: 10px;
	z-index: 1;
	margin:0;
	padding:0;
}
.photo_txt ul {
    margin:0 !important;
    padding:0 !important;
    list-style-image:none;
    list-style-position:outside;
    list-style-type:none;
}
.photo_txt li {
    background-image:none;
    line-height:115% !important;
    list-style-image:none;
    list-style-type:none;
    margin:0 !important;
    padding:0 !important;
}
.photo_details .photo_date_details{
    margin-top:10px;
}
.photo_larr{
    background:url(../images/larr.jpg) no-repeat right 20px;
}
.photo_details{
    background:#E0EAEF;
    padding:20px;
    border-radius:4px;
    font-size:14px;
}
.club_photo .karma_buttons{
    padding:5px;
}

.photo_sub_details{
    padding:10px 20px;
    font-size:14px;
}

.photo_sub_details .taglinebar{
    margin:0px;
    margin-top:10px;
}

/*---------------------------- ARTICLE CONTENTS ------------------------------*/
.con_pt {
	display:block;
	padding: 8px;
	margin: 5px;
	border:solid 1px gray;
	width:260px;
	float:right;
	background-color:#FFFFFF;
}

.con_pt_heading {
	display: block;
	margin-bottom:6px;
}

/*-------------------------------- SHOP CART ---------------------------------*/
.cart_item{
	float: left;
}
.cart_total{
	clear:both;
	border-top:solid 1px gray;
	margin-top:10px;
	padding-top:5px
}
.cart_price{
    float: right;
    font-weight: normal;
}
.cartrow2, .cartrow1 {
    clear: both;
    overflow: hidden;
    padding: 3px 5px;
    font-size:11px;
    font-weight: bold;
}
.cartrow1{
	background-color:#EBEBEB;
}
.cartrow2{
	background-color:#FFFFFF;
}

/*----------------------- MODULE "LATEST COMMENTS" ---------------------------*/
.mod_com_userlink{
	color:#666;
}
.mod_com_line{
    font-size:14px;
}
.mod_com_targetlink{
    color:#333;
}
.mod_com_details{
    font-size:10px;
    margin-bottom:6px;
}
.mod_com_details a{
    text-decoration:none;
}
.mod_com_all{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/comments.png) no-repeat;
    color:#333;
}
/*--------------------- MODULE "LATEST FORUM WEB2.0" -------------------------*/
.mod_fweb2_userlink{
	text-decoration:underline;
	color:#666666;
}
.mod_fweb2_date{
    text-align:center;
    background:#888;
    border-radius: 5px;
    color: #FFF;
    padding: 2px 6px;
    text-decoration: none;
    text-shadow: 0 1px 0 #333;
    width: 100px;
}
.mod_fweb2_date_new{
    background: #0FADE5;
    color: #FFF;
}
.mod_fweb2_topiclink{
	text-decoration:underline;
	font-weight:bold;
}
.mod_fweb2_shorttext{
	font-style:italic;
	color:#999999;
}

/*------------------------------- PATHWAY ------------------------------------*/

/*-------------------------- MODULE "CALENDAR" -------------------------------*/
.calendar{
	font-family:Verdana, Arial, Helvetica, sans-serif;
	font-size:12px;
	border: solid 1px black;
	width:100%;
	text-align:center;
}
.calendar_top{
	height: 30px;
	background-color:#006699;
	color:#FFFFFF;
}
#calendar_nav{
	height: 30px;
	background-color:#006699;
	color:#FFFFFF;
}
#calendar_nav a{
	color:#FFFFFF;
	text-align:center;
}
.calendar_dayname{
	font-size: 10px;
	height: 30px;
	background-color:#EBEBEB;
}
.calendar_day{
	font-size: 10px;
	padding:4px;
}
.calendar_today{
	font-size: 10px;
	padding:4px;
	background-color:#EBEBEB;
	border:solid 1px gray;
}
.calendar_daylink{
	text-decoration: underline;
}
.calendar_toplink{
	text-decoration: none;
	color:#FFFFFF;
}
.calendar_toplink:hover{
	text-decoration: none;
	color:yellow;
}

/*-------------------------- UNIVERSAL CATALOG -------------------------------*/
.uc_new{
	color:#FF0000;
	font-style:italic;
	padding:5px;
	vertical-align: super;
}
.uc_list_item{
	padding: 2px;
	margin: 2px;
}
.uc_itemfieldlist{
	margin: 0px;
    margin-top:10px;
	list-style: none;
}
.uc_itemfield{
	font-size:14px;
}
.uc_itemlink{
	font-size:20px;
}
.uc_list_itempic{
	background-color: #FFFFFF;
	border: solid 1px black;
	padding: 2px;
}
.uc_list_itemdesc{
	padding-left: 0px;
    overflow:hidden;
}

.uc_thumb_item{
	padding: 2px;
	margin: 2px;
	background-color: #FFFFFF;
	border:solid 1px silver;
	display:block;
	width:140px;
	height:170px;
	float:left;
	text-align:center;
}
.uc_thumb_itemlink{
	font-size:12px;
}
.uc_detailfield{
	margin:0px;
	margin-bottom: 8px;
    padding:0px;
}
.uc_detaillist{
	margin:0px;
    padding-left:15px;
	list-style: none;
}
.uc_detaillink{
	font-size:16px;
	margin-top:15px;
	text-decoration:underline;
}
.uc_detaillink:hover{
	color:#FF6600;
	font-size:16px;
	margin-top:15px;
	text-decoration:underline;
}
.uc_detailtags{
	margin-top: 10px;
	margin-bottom: 10px;
}
.uc_detailimg div{
    border-radius:4px;
    border:1px solid #C3D6DF;
    padding:4px;
}
.uc_detailrating{
	margin:0px;
	margin-bottom:8px;
	margin-top:8px;
	border:solid 1px gray;
	padding:3px;
}
.uc_findsame{
	color: gray;
}
.uc_tagline{
	border-top:dashed 1px #C3D6DF;
	margin-top: 6px;
	padding-top:2px;
	padding-bottom:8px;
	font-size: 10px;
	width: 100%;
}
.uc_sortform{
	padding: 5px;
	border: solid 1px gray;
	background-color:#FFFFFF;
	margin:1px;
}
.uc_queryform{
	padding: 5px;
	border-bottom: solid 1px gray;
	margin-bottom:10px;
}
.uc_rating{
	margin-bottom: 8px;
    float:right;
}
.uc_toolbar{
	margin: 0px;
	margin-bottom:20px;
}
.uc_item_edit{
    margin-top:6px;
    float:right;
}
.uc_item_edit_link{
    color:#375E93;
    padding:2px;
    padding-left:20px;
    background:url(../images/icons/edit.png) no-repeat left center;
}
.uc_cat_search{
    margin:15px 0px;
}
.uc_cat_search table{
    margin-bottom:3px;
}

.uc_cat_search table input{
    border:solid 1px #4DA4CF;
    padding:2px;
}

.uc_search_in_cat{
    margin-bottom:10px;
}

.uc_search_in_cat a{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/folder.png) no-repeat;
}

/*----------------------MODULE "LATEST FROM CATALOG"--------------------------*/
.uc_latest_item{
	padding: 2px;
	margin: 2px;
	background-color: #FFFFFF;
	border:solid 1px silver;
	display:block;
	width:140px;
	height:170px;
	float:left;
	text-align:center;
}
.uc_latest_link{
	font-size:12px;
}
.uc_latest_title{
	list-style-position:inside;
	list-style:square;
}
.uc_alpha_list{
    border-radius:4px;
    background-color:#E0EAEF;
    padding:5px 10px;
    margin-bottom: 5px;
}
.uc_alpha_link{
	font-size:18px;
	padding-right:10px;
}

/*------------------------- MODULE "SITE TAGS" -------------------------------*/
a.tag:hover{
	background-color: #375E93;
	color: #fff !important;
    text-decoration: none;
}
a.tag{
	padding:0 2px;
}

/*---------------------------PERSONAL MENU -----------------------------------*/
.user_menu_module {
    float: right;
}
.user_menu_module li {
    float: left;
    list-style: none;
    margin: 0;
    position: relative;
}
.user_menu_module li a {
    color: #bfdeff;
    display: block;
    padding: 0 9px 0 26px;
    margin-right: 1px;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: 5px center;
}
.user_menu_module li a:hover, .user_menu_module li a.selected, .user_menu_module li a.hover{
    color:#FFF;
    background-color:#5D90D1;
}
.user_menu_module li ul {
    background: #5d90d1;
    color: #FFF;
    display: none;
    padding: 0;
    position: absolute;
    top: 59px;
    z-index: 1000;
    min-width: 150px;
    box-shadow: 2px 4px 3px #555;
}
.user_menu_module li ul li {
    float: none;
}
.user_menu_module li ul li a:hover {
    background-color: #1f3147;
}
.user_menu_module li ul li a {
    height: 30px;
    line-height: 30px;
    margin: 0;
}
.user_menu_module li:last-child a {
    margin-right: 0;
}
.user_menu_module li.my_profile a{ background-image: url(../images/icons/user.png); }
.user_menu_module li.my_messages a{ background-image:url(../images/icons/message.png); }
.user_menu_module li.my_messages a.has_new { color:#FFE900; }
.user_menu_module li.my_blog a{ background-image:url(../images/icons/blog.png); }
.user_menu_module li.my_photos a{ background-image:url(../images/icons/folder_picture.png); }
.user_menu_module li.add_photos a{ background-image:url(../images/icons/addphoto.png); }
.user_menu_module li.my_content a{ background-image:url(../images/icons/folder_page.png); }
.user_menu_module li.add_content a{ background-image:url(../images/icons/add.png); }
.user_menu_module li.admin a{ background-image:url(../images/icons/cpanel.png); }
.user_menu_module li.logout a{ background-image:url(../images/icons/logout.png); }
.user_menu_module li.register a{ background-image:url(../images/icons/register.png); }
.user_menu_module li.login a{ background-image:url(../images/icons/key.png); }
.user_menu_module li.my_balance a{ background-image:url(../images/billing/balance.png); }

.usr_msgmenu_bar{
	background-color:#E0EAEF;
	margin-bottom:10px;
    border-radius:0px 0px 4px 4px;
    color:#375E93;
    clear: both;
    padding: 7px;
}
.usr_msgreply_source{
	padding:7px;
    border-radius:4px;
    border:1px solid #C3D6DF;
	margin-bottom:10px;
    overflow-y: auto;
    max-height: 250px;
}
.usr_msgreply_author{
	font-style:italic;
	color:#666666;
	text-align:right;
	margin: 5px 0 0;
}
.usr_msg_smilebox a{
    margin:4px;
}
.usr_msg_smilebox{
	background-color:#EBEBEB;
    width:100%;
	clear:both;
}
.bb_add_photo{
	background-color:#EBEBEB;
    width:100%;
	clear:both;
	padding:4px;
}
.usr_msg_bbcodebox{
	padding:0px;
	background:url(../images/bbtoolbar.jpg) repeat-x left top #EFEFEF;
	overflow:hidden;
	_height:1%;
    width:100%;
    position: relative;
}
.bbcodebox_height {
    height: 58px;
}
#imginsert {
    position: absolute;
    top: 26px;
}
.usr_bb_button{
	display:block;
    margin:1px;
	padding:4px;
	float:left;
}
.usr_bb_button:hover{
    border: solid 1px #83BBD9;
	padding:3px;
	background:url(../images/bbbtn.jpg) repeat-x left bottom #DFF2F9;
}
.usr_bb_hint{
	display:block;
	margin:2px;
	padding:5px;
	float:left;
	color:#666666;
}

a.new_messages_link{ color:#fff; background:#F00; font-weight:bold; padding:2px; text-decoration:none; }
a.new_messages_link:hover{ color:#F00; background:#Fff; font-weight:bold; padding:2px; text-decoration:none; }

/************************** Новые пользователи ********************************/

.mod_new_user{
    clear:both;
    overflow:hidden;
    margin-bottom:5px;
}
.mod_new_user_avatar {
    float:left;
    margin-right:10px;
    padding:1px;
    background:#FFF;
    border:1px solid #6E93C9;
    overflow:hidden;
}

.mod_new_user_link { font-size:14px; }

#mod_usr_search_form td{
    padding:4px;
}

/*----------------------------------------------------------------------------*/
.ajax_autogrowarea{
    border: 0 none;
    font-family: arial;
    font-size: 100%;
    margin: 0;
    padding: 3px 0;
    width: 100%;
	height:180px;
	min-height:180px;
	max-height:1000px;
}
.bb_tag_code{
    border:solid 1px silver;
    padding:8px;
    margin-top:5px;
    margin-bottom:5px;
    max-width:700px;
    overflow:auto;
}
.bb_tag_code ol{
    padding: 6px 0;
    padding-left:5px;
}
.bb_tag_hide{
	padding:8px;
	background-color:#FFFFCC;
    overflow: hidden;
}
.bb_tag_hide a{
	color:#333333;
	text-decoration:underline;
}
.bb_tag_spoiler .spoiler_title{ margin-bottom:3px; }
.bb_tag_spoiler .spoiler_body{
    margin-bottom:10px;
    padding:10px;
    background:#ECECEC;
    border-radius:4px;

}
.bb_img {
	max-width: 550px;
}
/*----------------------------- COMPONENT "FAQ" ------------------------------*/
.faq_subcats, .faq_cats{
	padding:10px;
    padding-bottom:5px;
	background-color:#E0EAEF;
	margin-bottom:10px;
    border-radius:4px;
}
.faq_cat_link{
    font-size:18px;
}
.faq_cat_desc{
    margin-bottom:5px;
}

.faq_questtext{
	font-size:18px;
    margin-bottom:10px;
    color:#375E93;
}
.faq_quest_link {
    margin-bottom:5px;
}
.faq_quest_link a{
    font-size:14px;
    text-decoration:none;
}

.faq_answertext{
    font-size:16px;
    margin-bottom:10px;
}

.faq_answertext p{
    margin-bottom:10px;
}

.faq_questdate{
	padding:2px;
	font-size:11px;
	text-align:center;
	background-color:#EBEBEB;
	width:100px;
	float:right;
}
.faq_questuser a{
    color:#375E93;
}
.faq_questuser{
	padding-left:20px;
	font-size:11px;
	text-align:center;
	margin-top:2px;
    margin-right:4px;
	float:right;
	background:url(../images/icons/user.png) no-repeat;
}
.faq_questcat{
	padding:2px;
	font-size:11px;
	text-align:center;
	margin-left:4px;
	float:right;
}
.faq_questcat a{
	color:#333333;
}
.faq_quest{
	margin-bottom:4px;
	border-radius:4px;
    border:1px solid #C3D6DF;
    padding:8px;
}
.faq_send_quest{
    border-radius:4px;
    background-color:#E0EAEF;
    float:right;
    padding:5px 8px;
}
.faq_send_quest a{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/add.png) no-repeat;
	text-decoration:underline;
	color:#375E93;
}

#faq_message{
    width:100%;
    height:200px;
    margin-bottom:10px;
    border:solid 1px #203047;
}
.faq_add_cat{
    margin-bottom:10px;
    color:#375E93;
}
/*------------------------------- KARMA --------------------------------------*/
.karma_form{
	text-align:center;
	padding:10px;
	background-color:#E0EAEF;
	border-radius:4px;
}
.karma_form #karmavotes {
	color:#333;
}

/*------------------------------ TAG BAR -------------------------------------*/
.taglinebar{
	margin:10px 0px;
}
.taglinebar .label{
	padding-right:18px;
	background:url(../images/icons/tag.png);
	background-repeat:no-repeat;
	background-position:right;
	font-weight:bold;
	margin-right:4px;
}
.tag_searchcat{
	color:#666666;
	text-decoration:underline;
	font-size:16px;
}
.tag_searchitem{
	text-decoration:underline;
	font-size:16px;
}
.tagsearch_bar{
	font-size:10px;
	text-align:right;
	float:right;
}
.tagsearch_item{
	float:left;
	padding:5px;
}

/*------------------------ MODULE "LATEST BLOG POSTS" ------------------------*/
.mod_blog_userlink{
	text-decoration:underline;
	color:#666666;
}
.mod_blog_karma{
	text-align:center;
	font-size:18px;
}

/*------------------------- MODULE "LATEST FAQ QUESTS" -----------------------*/
.mod_faq_date{
	font-style:italic;
	color:#999999;
}

/* ------------------------ MODULE "LATEST BOARD ITEMS" --------------------- */

.new_board_items li{
	padding:0px;
    padding-left:20px;
    background:url(../images/icons/note.png) no-repeat left;
    list-style:none;
    margin:0px;
	margin-bottom:4px;
    font-size:14px;
}
.new_board_items li.vip a{
    color:#ED8115;
    font-weight:bold;
}
.new_board_items{
	padding:0px;
	margin:0px;
}

.board_city{
    color:#888;
}
/* ---------------------------- MODULE "LATEST CLUBS" ----------------------- */

.mod_clubs .data .details span{ color: #999; padding-left:19px; margin-right:4px; font-size:10px; height:16px;line-height:16px;display:block; }

/* ----------------------------- MODULE "USER RATING" ----------------------- */

.mod_user_rating td.avatar{ text-align:center; }
.mod_user_rating a { font-size:14px; font-weight:bold; margin-left:15px; float:left; }
.mod_user_rating div.karma { font-size:14px; font-weight:bold; margin-left:5px; float:left; }
.mod_user_rating div.rating { font-size:14px; font-weight:bold; margin-left:5px; float:left;  }

/* ========================================================================== */
/* =========================== КОМПОНЕНТЫ =================================== */
/* ========================================================================== */

/* ========================================================================== */
/* ============================== БЛОГИ ===================================== */
/* ========================================================================== */

.blog_moderate_link{
	color:#FF3300;
	text-decoration:underline;
}
.blog_moderate_yes{
	color:#009933;
	font-size:14px;
	text-decoration:underline;
}
.blog_moderate_no{
	color:#CC0000;
	font-size:14px;
	text-decoration:underline;
}
.blog_desc{
	clear:both;
}
.blog_toolbar{
	position:relative;
	background-color:#E0EAEF;
    padding:5px 8px;
    border-radius:4px;
    display:inline-block;
    float:right;
}
.blog_records{
	clear:both;
}
.blog_cat{
	float:left;
	padding:2px;
	height:20px;
	line-height:20px;
}
.blog_catlist{
	float:left;
	overflow:hidden;
	_height:1%;
	margin:4px 0 20px 0;
}

.blog_post_data strong{
    color:#375E93;
}

.blog_post_avatar{
    padding:2px;
    border:solid 1px #375E93;
    margin-right:10px;
}

.blog_post_body{
	padding:4px;
	margin-top:15px;
	margin-bottom:15px;
}
.blog_post_nav{
	margin:5px 0;
	padding:2px;
	background-color:#E0EAEF;
	overflow:hidden;
    border-radius:4px;
	clear:both;
	overflow:hidden;
}
.blog_post_nav a {
	height: 28px;
	font-size:14px;
}
.blog_post_nav .next {
	float:right;
	background:url(../images/icons/big/next.png) no-repeat right;
	padding: 5px 30px 0 0;
}
.blog_post_nav .prev {
	float:left;
	background:url(../images/icons/big/prev.png) no-repeat;
	padding: 5px 0 0 30px;
}
.blog_btnlink{
	padding:10px;
	margin:2px;
	border:solid 1px silver;
	width:120px;
}
.blog_fa{
	margin:0px;
	border: solid 1px gray;
	padding:10px;
	background-color:#FFFFFF;
}
.blog_fa_title{
	font-weight:bold;
}
.blog_fa_desc{
	border-bottom:solid 1px gray;
	margin-bottom:10px;
	padding-bottom:8px;
}
.blog_fa_entries{
	background-color:#EBEBEB;
	border:solid 1px gray;
	padding: 8px;
	margin-top:10px;
	display:none;
}
.blog_fa_browse{
	border:solid 1px;
}
.fa_attach{
	margin-top:10px;
	border-top: 1px dotted #A8C2CF;
	display:block;
}
.fa_attach_title{
	margin-top:6px;
	margin-bottom:6px;
	font-weight:bold;
	font-size:10px;
    color: #5F98BF;
}
.fa_file_desc{
	font-size:10px;
}
.fa_file_link{
	font-size:10px;
	margin-left:5px;
}
.blog_attachbox{
	overflow:hidden;
	margin-top:5px;
}
.blog_attachthumb{
	float:left;
	width:100px;
	padding:8px;
	border:solid 1px silver;
	margin-left:2px;
}
.blog_entry{
    clear:both;
}
.blog_entry_title{
	font-size:24px;
	padding:0px;
	float:left;
}
.blog_entry_info{
	font-size:12px;
	color:#333333;
	padding:0px;
	margin-top:5px;
	clear:both;
}
.blog_entry_info a{
	color:#375E93;
}
.blog_entry_date{
	background:url(../images/icons/calendar.png) no-repeat left center;
    padding:1px;
	padding-left:20px;
    margin-right:10px;
}
.blog_entry_title_td{
	background-color:#FFF;
    padding-bottom:12px;
}
.blog_entry_text{
    margin-bottom:15px;
}
#create_club td {
    padding: 5px 0;
}
.club_blog_post{
    margin:5px 0;
	padding: 5px 0;
	border-bottom: 1px solid #C3D6DF;
}
.club_blog_post .club_post_title {
	font-size:14px;
}
.club_blog_post .club_post_author {
	font-size:12px;
	color: #5F98BF;
}
.club_blog_post .club_post_descr {
	color: #AAAAAA;
}
.blog_entry_karma{
	font-size:20px;
	float:right;
	margin-right:15px;
}
.blog_entry_karma_td{
	background-color:white;
	border:solid 1px #666666;
	text-align:center;
}
.post_karma{
	background:url(../images/icons/rating.png) no-repeat left center;
	padding:1px;
    padding-left:20px;
    display:inline-block;
    margin-right:10px;
    font-size:14px;
}
.post_hits{
	background:url(../images/icons/eye.png) no-repeat left center;
	padding:1px;
    padding-left:20px;
    display:inline-block;
    margin-right:10px;
    font-size:14px;
    color: gray;
}
.blog_comments_link{
	font-size:12px;font-weight:bold;
	text-decoration:underline;
	background:url(../images/icons/comment.png) no-repeat left center;
    padding:1px;
	padding-left:20px;
}
.blog_comments{
	margin-bottom:20px;
	font-size:12px;
	padding:5px 10px;
	border:solid 1px #C3D6DF;
	display:inline-block;
    border-radius:4px;
}
.blog_comments .tagline{
	text-decoration:none;
	background:url(../images/icons/tag.png) no-repeat left center;
    padding:1px;
	padding-left:20px;
	margin-left:10px;
}
.blog_comments .editlinks a{
	color:#666666;
}
.blog_authorsall{
	color:#666666;
}
.blog_authorslink{
	color:#666666;
	text-decoration:underline;
}
.blog_type_menu{
	clear:both;
	overflow:hidden;
	margin-bottom:10px;
}
.blog_type_link{
	display:block;
	float:left;
	margin-right:15px;
	font-size:18px;
    color:#375E93;
    padding:5px 0px;
}
.blog_type_active{
	display:block;
	float:left;
	margin-right:15px;
	color:#000;
    font-size:18px;
    text-decoration:none;
    background:#E0EAEF;
    padding:5px 10px;
    border-radius:4px;
}
.blog_full_list .blog_title_td{
    padding-left:0px;
}
.blog_full_list .blog_title,
.blog_full_list .blog_user,
.blog_full_list .blog_posts,
.blog_full_list .blog_comm{
    padding:1px;
    padding-left:20px;
}
.blog_post_hits {
    color: #ccc;
    float: right;
    font-style: italic;
    margin: -30px 0 0;
}
.blog_full_list .blog_title{ font-size:16px; background:url(../images/icons/blog.png) no-repeat left center; }
.blog_user{ background:url(../images/icons/user.png) no-repeat left center; padding:2px 10px 2px 20px; }
.blog_full_list .blog_posts{ background:url(../images/icons/posts.png) no-repeat left center; }
.blog_full_list .blog_comm{ background:url(../images/icons/comment.png) no-repeat left center; }

.blog_full_list .blog_rss{
    background:url(../images/icons/rss.png) no-repeat left center;
    width:16px;
    height:16px;
    display: block;
}
.blogs_full_rss { margin-top:15px; }
.blogs_full_rss a{
    background:url(../images/icons/rss.png) no-repeat left center;
    padding:1px;
    padding-left:20px
}
.con_rss_icon {
	float:right;
	margin: 10px 5px;
}
.con_rss_icon a {
	text-decoration:none;
}
/* ========================================================================== */
/* ========================= ДОСКА ОБЪЯВЛЕНИЙ =============================== */
/* ========================================================================== */

.bd_item{
	margin-bottom:4px;
	border:solid 1px #C3D6DF;
    padding:12px;
    border-radius:4px;
}

.bd_item_vip{
	margin-bottom:4px;
	border:1px solid #FFCC99;
	background:#FFFFD9;
    padding:12px;
    border-radius:4px;
}

.board_toolbar{
	margin-bottom:10px;
}
.board_toolbar table{
	padding:4px;
	background-color:#EBEBEB;
}
.board_toolbar a{
	text-decoration:underline;
	color:#666666;
}

div.bd_title{
	margin-bottom:0px;
}

div.bd_title a{
	font-size:16px;
	font-weight:bold;
	margin:0px;
}

.bd_item_vip div.bd_title a{
	font-size:16px;
	font-weight:bold;
	margin:0px;
    color:#ED8115;
}

img.bd_image_small{
	padding:2px;
	border:solid 1px #C3D6DF;
	margin-right:10px;
}

.bd_item_vip img.bd_image_small{
	padding:2px;
	border:1px solid #FFCC99;
	margin-right:10px;
}

div.board_gallery{
	margin-top:20px;
	margin-bottom:10px;
}

.board_categorylist{
    margin-bottom:30px;
    margin-top: 10px;
}
.board_description {
    margin: 0 0 30px 0;
}
.bd_text{
	margin:0px;
	clear:both;
}
.bd_moderate_link{
	float:right;
}
.bd_moderate_link span{
	float:right;
	padding:0 5px 0 18px;
}
div.bd_item_details{
	margin-top:20px;
	float:right;
}
div.bd_item_details a, .bd_moderate_link a{
	color:#666;
}

div.bd_item_details_full{
	margin-bottom:10px;
}

div.bd_item_details_full a{
	color:#666666;
}

div.bd_item_details span, div.bd_item_details_full span{
	height:18px;
	line-height:18px;
	padding:2px;
	padding-left:18px;
	padding-right:10px;
	font-size:12px;
}

.bd_text_full{
    padding:20px 15px;
    font-size:16px;
    border-radius:4px;
    border:1px solid #C3D6DF;
    margin-top:15px;
}

div.bd_links{
	padding:0px;
	margin-top:10px;
    text-align:right;
}

div.bd_links span{
	height:18px;
	line-height:18px;
    padding:2px;
	padding-left:20px;
	padding-right:10px;
}

div.bd_links span.bd_message{
	background:url(../images/icons/message.png) no-repeat 0px 2px;
}
div.bd_links span.bd_author{
	background:url(../images/icons/posts.png) no-repeat 0px 2px;
}

span.bd_item_is_vip{
    background:url(../images/icons/vip.png) no-repeat;
    color:#ED8115;
    font-weight:bold;
}

span.bd_item_date{
	background:url(../images/icons/calendar.png) no-repeat;
}

span.bd_item_city{
	background:url(../images/icons/city.png) no-repeat;
}
span.bd_item_hits{
	background:url(../images/icons/eye.png) no-repeat scroll center left;
}
span.bd_item_edit{
	background:url(../images/icons/edit.png) no-repeat;
}

span.bd_item_delete{
	background:url(../images/icons/no.png) no-repeat;
}
span.bd_item_publish{
	background:url(../images/icons/yes.png) no-repeat;
}
span.bd_item_user{
	background:url(../images/icons/user.png) no-repeat;
}
span.bd_item_cat{
	background:url(../images/icons/folder_table.png) no-repeat;
}
span.bd_item_status_ok{
	color:green;
	background:url(/components/board/images/status_ok.gif) no-repeat;
}
span.bd_item_status_bad{
	color:red;
	background:url(/components/board/images/status_bad.gif) no-repeat;
}

img.bd_cat_main_icon{
	margin-right:10px;
}

div.bd_cat_main_title a{
	font-size:18px;
	color:#375E93;
}
div.bd_cat_main_obtypes a{
	color:#5F98BF;
}

/* ========================================================================== */
/* ============================== КАТАЛОГ =================================== */
/* ========================================================================== */

#shop_toollink_div{
    border-radius:4px;
    background-color:#E0EAEF;
    float:right;
    padding:8px 8px;
	clear:both;
	overflow:hidden;
}

#shop_toollink_div a{
    color:#375E93;
}

#shop_cartlink{
	float:left;
	background:url(../images/icons/cart.png) no-repeat 2px 2px;
    font-weight:bold;
	padding-left:25px;
	padding-right:5px;
}

#shop_searchlink{
	float:left;
	background:url(../images/icons/search.png) no-repeat 2px 2px;
	padding-left:25px;
	padding-right:5px;
}
#shop_addlink{
	float:left;
	background:url(../images/icons/add.png) no-repeat 2px 2px;
	padding-left:22px;
	padding-right:5px;
}
#cart_buttons{
	margin-top:20px;
	text-align:center;
	overflow:hidden;
}
#cart_buttons1{
	margin:2px;
	float:right;
}
#cart_buttons2{
	margin:2px;
	float:left;
}

#cart_total{
	text-align:right;
	font-size:16px;
	margin-top:5px;
	padding-top:5px;
	border-top:solid 1px silver;
}
#cart_total span{
	font-weight:bold;
}

#catalog_list_itempic{
	padding:10px;
}

#shop_small_price{
	width:110px;
	height:26px;
	margin-left:auto;
	margin-right:auto;
	line-height:26px;
	text-align:center;
	margin-top:5px;
	font-size:14px;
	font-weight:bold;
	color:#047700;
}

#shop_price{
	float:left;
	font-size:14px;
	margin-left:15px;
	margin-top:10px;
	height:43px;
	line-height:43px;
	width:190px;
	background:url(/components/catalog/images/shop/price.jpg) no-repeat;
}
#shop_price span{
	margin-left:20px;
	font-weight:bold;
}
#shop_ac_item_link img{
	margin-left:2px;
	margin-top:12px;
}
#shop_ac_itemdiv{
	margin-left:5px;
	margin-top:10px;
}

.catalog_sortform{
	text-align:right;
	clear:both;
	overflow:hidden;
}
.catalog_sortform table{
	float:right;
    margin:10px 0px;
}
.catalog_list_item{
    border-radius:4px;
    border:1px solid #C3D6DF;
    margin-bottom:4px;
    padding:8px;
}
#catalog_item_table{
	width:100%;
}
#shop_list_buttons{
	float:right;
	clear:both;
    margin-top:15px;
}
div.uc_subcats{
	padding:10px;
	border:solid 1px silver;
	background-color:#FFF;
	margin-bottom:15px;
}
ul.uc_cat_list{
	list-style:none;
	margin:0px;
	padding:0px;
	overflow:hidden;
}
ul.uc_cat_list li.uc_cat_item{
    font-size:18px;
    padding-left:23px;
    background: url(../images/icons/folder.png) no-repeat left center;
    height:18px;
    line-height:18px;
    margin:0px;
    margin-bottom:10px;
}
.hint{
    color:gray;
}
#shop_moder_form{
    padding:15px;
}
#shop_moder_form p.notice{
    font-weight:bold;
}

/* ========================================================================== */
/* ============================== КЛУБЫ ===================================== */
/* ========================================================================== */

div.new_club {
   margin-bottom:15px;
   padding:9px;
   padding-left:30px;
   padding-right:15px;
   background:url(../images/icons/add.png) no-repeat 10px center #E0EAEF;
   float:right;
   border-radius:4px;
   background-color:#E0EAEF;
}
div.new_club a{
	text-decoration:none;
	border-bottom:1px dashed;
}

div.club_entry,
div.club_entry_vip{
    overflow:hidden;
    margin-bottom:5px;
    padding:15px;
    border:solid 1px #C3D6DF;
    border-radius:4px;
    clear:both;
    position: relative;
}

.user_role {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #cc3300;
}
.member {
    color: #0033cc;
}
.moderator {
    color: #009933;
}
div.club_entry_vip{
    background: #FFFFD9;
    border: 1px solid #FFCC99;
}

div.club_entry .image,
div.club_entry_vip .image{ padding: 2px; float: left; margin-right:10px; }

div.club_entry .data,
div.club_entry_vip .data{ float: left; }

div.club_entry .data .title,
div.club_entry_vip .data .title{ margin-bottom: 5px; margin-top:5px;  }
div.club_entry .data .title a,
div.club_entry_vip .data .title a{ font-size: 17px; }
div.club_entry_vip .data .title a{ color:#ED8115;  }
div.club_entry .data .title a.private,
div.club_entry_vip .data .title a.private{ font-size: 16px; font-weight:bold; color:gray; }
div.club_entry .data .details,
div.club_entry_vip .data .details { float:left; }
div.club_entry .data .details span,
div.club_entry_vip .data .details span{ color: #666; padding:2px; padding-left:20px; margin-right:4px; font-size:10px; height:20px;line-height:20px; }
div.club_entry .data .details span.rating,
div.club_entry_vip .data .details span.rating{ background:url(../images/icons/rating.png) no-repeat left center; }
div.club_entry_vip .data .details span.vip{ background:url(../images/icons/vip.png) no-repeat left center; }
div.club_entry .data .details span.date,
div.club_entry_vip .data .details span.date{ background:url(../images/icons/calendar.png) no-repeat left center; }
div.club_entry .data .details span.members,
div.club_entry_vip .data .details span.members{ background:url(../images/icons/groups/registered.png) no-repeat left center; }

table.club_full_entry { overflow:hidden; margin-bottom:10px; width:100%; }
table.club_full_entry td.left { overflow:hidden; margin-bottom:10px; width:200px; }
table.club_full_entry .image{ width:200px; padding: 2px; margin-right:14px; }

table.club_full_entry .data .details { margin-bottom:10px; border-bottom:solid 1px #789DAF; padding-bottom:8px; background:#E0EAEF; padding:8px;}
table.club_full_entry .data .details span{ color: #333; padding:1px; padding-left:20px; margin-right:4px; height:18px; }
table.club_full_entry .data .details span.vip{ background:url(../images/icons/vip.png) no-repeat left center; color:#ED8115; font-weight:bold; }
table.club_full_entry .data .details span.rating{ background:url(../images/icons/rating.png) no-repeat left center; }
table.club_full_entry .data .details span.date{ background:url(../images/icons/calendar.png) no-repeat left center; }
table.club_full_entry .data .details span.members{ background:url(../images/icons/groups/registered.png) no-repeat left center; }

table.club_full_entry .data .description { margin-right:20px; margin-left:8px; margin-bottom:5px; clear:both; }

table.club_full_entry .members_list { width:200px; clear:both; }
table.club_full_entry .members_list .title { margin-bottom:10px; padding-top:8px; padding-left: 8px; font-weight:bold; }
table.club_full_entry .members_list .list { clear:both; overflow:hidden; }

table.club_full_entry .clubcontent { clear:both; margin-bottom:10px; }
table.club_full_entry .clubcontent p { padding:0px; padding-left:5px; margin-top:0px; }
table.club_full_entry .clubcontent div.title { padding:10px 10px 10px 40px; border-top:solid 1px #789DAF; color:#375E93; line-height:30px; height:30px; font-size:16px; }
table.club_full_entry .clubcontent div.blog .title { background:url(../images/icons/big/blog.png) 4px center no-repeat #E0EAEF; }
table.club_full_entry .clubcontent div.album .title { background:url(../images/icons/big/images.png) 4px center no-repeat #E0EAEF; }
table.club_full_entry .clubcontent ul li a.on_moder { color:#FF6600; }
table.club_full_entry .clubcontent div.album a.delete { color:#FF0000; }

table.club_full_entry .clubcontent span.new{ color:#009900; font-weight:bold; }

table.club_full_entry .clubcontent ul { margin:10px 0 10px 30px; }
table.club_full_entry .clubcontent ul li { margin-bottom:3px; margin-left:0; padding-left:0; }
table.club_full_entry .clubcontent ul li.all a{ color:#999999; }
table.club_full_entry .clubcontent ul li.service a{ color:#009900; }

table.club_full_entry .clubcontent #add_album_form input.text { width:300px; border:solid 1px silver; }
.service { color:#009900; margin-left:10px; }

table.club_full_entry .clubmenu { overflow:hidden; clear:both;  }
table.club_full_entry .clubmenu div { float:right; line-height:24px; height:24px; margin:0 15px 10px 0; }
table.club_full_entry .clubmenu div.join{ padding-left: 32px; background:url(../images/icons/clubs/join.png) 0px center no-repeat; }
table.club_full_entry .clubmenu div.leave{ padding-left: 32px; background:url(../images/icons/clubs/leave.png) 0px center no-repeat; }
table.club_full_entry .clubmenu div.config{ padding-left: 32px; background:url(../images/icons/clubs/config.png) 0px center no-repeat; }
table.club_full_entry .clubmenu div.invite{ padding-left: 32px; background:url(../images/icons/clubs/invite.png) 0px center no-repeat; }
table.club_full_entry .clubmenu div.messages{ padding-left: 32px; background:url(../images/icons/clubs/messages.png) 0px center no-repeat; }

table.club_full_entry .wall .header{ background:#E0EAEF; padding:10px; border-top:solid 1px #789DAF; font-size:16px; color:#375E93; }
table.club_full_entry .wall .body{ padding:10px; }
table.club_full_entry .wall #addwall{ padding:0px; padding-right:10px;}
.member_list { float:left; position:relative; }
.member_list .online { font-size:10px; position:absolute; top:0; left:0; border-radius:3px; width:80%; opacity: 0.7; filter: alpha(opacity=70); }

/* ========================================================================== */
/* =========================== КОММЕНТАРИИ ================================== */
/* ========================================================================== */
.submit_cmm {
    float: left;
    margin: 6px 0;
}
.cmm_links {
    margin:15px 0 0 0;
}
.cmm_links span {
    padding: 1px 10px 1px 20px;
}
.cmm_links span.add_comment {
    background:url(../images/icons/comment.png) no-repeat left center;
}
.cmm_links span.subscribe {
    background:url(../images/icons/subscribe.png) no-repeat left center;
}
.cmm_links span.unsubscribe {
    background:url(../images/icons/unsubscribe.png) no-repeat left center;
}
.cmm_links span.cmm_rss {
    background:url(../images/icons/rss.png) no-repeat left center;
}
.left_border{
    padding: 0 0 0 8px;
}
.cmm_heading {
	font-family: "Trebuchet MS";
	font-size: 18px;
	color: #7192A6;
	margin-bottom: 4px;
	padding-top: 8px;
}
.cmm_icons{
	margin-bottom: 4px;
}
.cmm_icons a{
	color:#666666;
	text-decoration:underline;
}
.cmm_anchor{
	float:left;
	margin-left:10px;
	color:#333333;
}
.cmm_author {
	display: block;
	float: left;

	font-weight: bold;
}
.cmm_title {
	padding: 6px;
}
.cmm_votes{
	float: right;
	padding: 2px;
    margin-right:15px;
    color:silver;
    font-weight:bold;
}
.cmm_good{
    color:green;
}
.cmm_bad{
    color:red;
}
.cmm_date {
	color: #999;
	background-color: #999999;
	display: block;
	float: right;
	margin-right:6px;
	padding: 1px;
	padding-left:20px;
	background:url(../images/icons/calendar.png) no-repeat left center;
}
.cmm_pagebar {
	background-color:#EBEBEB;
	margin-bottom:4px;
}
.cmm_entry {
	width: 100%;
	margin:0;
	margin-bottom:5px;
	padding:2px;
}
.cmm_sublinks{
    display:block;
    margin-top:10px;
}
.cmm_sublinks a:hover{
    color:#375E93;
}
.cmm_sublinks a{
    color:#5F98BF;
    margin-right:6px;
}
.cmm_avatar {
	padding: 5px;
	background:#EBEBEB;
    border-radius:4px;
}
.cmm_content_av {
	padding: 10px;
	padding-left: 25px;
	padding-top: 7px;
	background:url(../images/icons/comments/pointer.jpg) no-repeat;

}
.cmm_content {
	padding-left: 6px;
}
.cmm_addentry a{
	text-decoration:underline;
}
.cmm_addentry {
	padding:20px;
	border:solid 1px gray;
	background-repeat:repeat-x;
	background-color:#E4E4E4;
}
.cmm_smilebox{
	padding:4px;
	border:solid 1px gray;
	background-color:#EBEBEB;
}
.cmm_codebar{
	width:100%;
	border:solid 1px gray;
	background-color:#FFFFFF;
}
.cmm_pageselect{
	border: solid 1px gray;
	background-color:#F3F3F3;
}
.cmm_submit{
	font-size:12px;
}
.cmm_all_title{
    margin-bottom:5px;
    color:#5F98BF;
    font-size:100%;
    font-weight:normal;
}
.cm_guest_name{
    margin:10px 0px;
    color:#375E93;
    font-weight:bold;
}
.cm_addentry{
	overflow:hidden;
	margin:4px;
}
.cm_editor{
	border:1px #CCC solid;
	padding:4px;
    border-radius:0 0 4px 4px;
}
.cm_codebar{
	margin:6px 0 0;
	overflow:hidden;
}
div.cm_smiles a{
	text-decoration:none;
}
div.cm_smiles{
	position:relative;
}

/* ========================================================================== */
/* ============================== КОНТЕНТ =================================== */
/* ========================================================================== */

#con_rating_block{
	margin-top:10px;
    padding:10px;
	margin-bottom:10px;
    background:#E0EAEF;
    border-radius:4px;
}

div.con_forum_link{
    margin-top:10px;
    margin-bottom:10px;
}

div.con_forum_link a{
    height:16px;
    line-height:16px;
    padding-left:18px;
    background:url(/images/icons/comments.gif) no-repeat left center;
}

/* ========================================================================== */
/* ============================== ФОРУМ ===================================== */
/* ========================================================================== */
.darkBlue-LightBlue { background-color:#385c89; background-image: -webkit-gradient(linear,left bottom,left top,from(#385c89),to(#4a79a9)); background-image: -moz-linear-gradient(bottom, #385c89, #4a79a9); background-image: -o-linear-gradient(bottom, #385c89, #4a79a9); color: #FFF; font-size:14px; clear: both; }
.darkBlue-LightBlue a { color:#FFF; border-color: #FFF; }
.darkBlue-LightBlue a:hover { color:#CCC; border-color: #CCC; }
.forum_cattitle{
	font-size: 18px;
	margin-bottom:6px;
	margin-top:10px;
	color:#5186B8;
}
.post_content{
    word-wrap: break-word;
    max-width: 767px;
    overflow: hidden;
}
.posts_table .darkBlue-LightBlue{
    font-size: 12px;
}
.last_post_img{
	float:right;
	padding:5px;
}
.added_later {
    color: #CCC;
    margin: 10px 0;
    display: block;
}
.forums_table{
    border:solid 1px #A8C2CF;
	box-shadow:0 0 20px rgba(0,0,0,0.3);
}

.forum_link{
	font-weight:bold;
}

.forum_desc{
	color: #444;
    margin: 5px 0 0;
}

.threads_table{
	border:solid 1px #A8C2CF;
	box-shadow:0 0 20px rgba(0,0,0,0.3);
}
.thread_link{
	font-weight:bold;
}

.thread_desc{
	color:#999999;
}
.thread_pagination{
	font-size:12px;
}
.thread_pagination a{
	color:#666666;
	text-decoration:underline;
}
.thread_pagination a:hover{
	color:#FF6600;
	text-decoration:underline;
}

.row1{
background-color:#E0EAEF; background-image: -webkit-gradient(linear,left bottom,left top,from(#E0EAEF),to(#FFF)); background-image: -moz-linear-gradient(bottom, #E0EAEF, #FFF); background-image: -o-linear-gradient(bottom, #E0EAEF, #FFF);
	border-bottom:#A8C2CF 1px solid;
}
.row11{
background-color:#E0EAEF;
	border-bottom:#A8C2CF 1px solid;
}
.row2{
	background-color:#FFF;
	border-bottom:#A8C2CF 1px solid;
}

.forum_poll_data{
	background-color:#F2F2F2;
}
.forum_poll_header{
	width:100%;
	background-color:#EBEBEB;
	border-bottom:dashed 1px #A8C2CF;
}
.forum_poll_title{
	font-weight:bold;
	font-size:18px;
}
.forum_poll_desc{
	font-size:12px;
    margin: 4px 0;
}
.forum_poll_gauge_title{
	font-size:12px;
}
.forum_poll_gauge{
	height:15px;
	background-color:#333333;
	margin-bottom:10px;
	font-size:10px;
	color:#FFFFFF;
	text-align:right;
}
.forum_poll_submit{
	margin:10px;
}
.forum_poll_param{
	margin:0 0 7px 0;
    font-size: 12px;
}

.post_usercell{
	background-color:#EFFAFF;
	border-top:solid 1px #A8C2CF;
    background-image: -moz-linear-gradient(right center , #EFFAFF, #FFF);
    background-image: -webkit-gradient(linear,left center,right center,from(#FFF),to(#EFFAFF));
    background-image: -o-linear-gradient(right, #EFFAFF, #FFF);
}
.post_userimg{
	margin:4px auto;
 	border:solid 1px #A8C2CF;
	padding:5px;
	background-color:white;
    border-radius:4px;
    width:64px;
}
.post_userawards{
	margin-top:3px;
	margin-bottom:3px;
}

.post_userrank{
	font-size:10px;
	color:#006699;
}
.post_userrank span{
	display:block;
	clear:both;
}
.post_userrank .user_rank{
	font-size:10px;
	color:#006699;
}
.post_userrank .admin_rank{
	font-size:10px;
	color:#660000;
}
.post_userrank .moder_rank{
	font-size:10px;
	color:#C00;
}

.post_usermsgcnt{
	font-size:10px;
}
.post_userkarma{
	padding:2px;
	margin:2px;
	font-size:10px;
}
.post_msgcell{
	background-color:#FFFFFF;
    border-top:solid 1px #A8C2CF;
	padding:6px;
}
.post_userlink{
	color:#003366;
	font-weight:bold;
}
.post_date{
	float: left;
}
.post_user_date {
    border-bottom: 1px dotted #CCCCCC;
    color: #666666;
    float: right;
    margin: 0 0 5px 5px;
}
.msg_links{
    float: right;
}
.votes_links{
    float: right;
    margin: 0 0 0 10px;
}
.post_editdate{
	color:#999999;
	margin:10px 0 0 0;
    padding: 7px 0 0 0;
	border-top:1px dotted #CCC;
    font-size: 11px;
    text-align: right;
}
.post_signature{
	margin-top:20px;
	border-top:solid 1px silver;
	padding-top:4px;
}

.forum_toollinks{
	margin-bottom:8px;
	text-align:right;
}

.forum_quote{
	padding:10px;
	border:dashed 1px gray;
	background-color:#EBEBEB;
	font-size:12px;
	color: gray;
	margin:8px;
}
.bb_quote {
    background-color: #FFFFD9;
    border: 1px solid #FFCC99;
    border-radius: 4px;
    color: gray;
    font-size: 12px;
    margin: 6px;
    padding: 8px;
    overflow: hidden;
}
.forum_quote_author{
	text-align:right;
}
.forum_toolbar{
	background-color:#E0EAEF;
    margin:5px 0px;
    border:solid 1px #A8C2CF;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
}
.forum_toolbar a:hover{
    color:#5F98BF;
}
.forum_toolbar a{
	color:#375E93;
	text-decoration:none;
}
.forum_navbar{
	margin-bottom:4px;
	background-color:#E0EAEF;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	padding:5px;
}
.forum_navbar a{
	color:#375E93;
	text-decoration:underline;
}
.forum_fa{
	margin: 6px 0 0 0;
	border: solid 1px #CCC;
    border-radius:4px;
	padding:7px;
}
.forum_fa_title{
	font-weight:bold;
}
.forum_fa_desc{
	border-bottom:solid 1px #FFCC99;
	margin-bottom:10px;
	padding-bottom:8px;
}
.forum_fa_entries{
	background-color:#FFFFD9;
	border:1px solid #FFCC99;
	padding: 8px;
	margin:6px 0 0 0;
    border-radius:4px;
	display:none;
}
.forum_postinfo{
	margin-bottom:15px;
}
.forum_texteditor{
	font-size:14px;
	border:solid 1px gray;
	width:100%;
	height:200px
}
.forum_zoom {
	opacity: 1.0;
}
.forum_zoom:hover {
	opacity: 0.9;
}
.forum_lostimg{
	padding:5px;
	border:solid 1px silver;
	color:#666666;
}
.forum_fast{
	margin-top:2px;
}
.forum_fast_header{
    color:#375E93;
    font-size:18px;
    margin-bottom:4px;
    padding:10px 0;
    padding-left:20px;
    background:url(../images/icons/edit.png) no-repeat left center;
}
.forum_fast_submit{
	text-align:right;
	margin-top:3px;
}
.forum_fast_form #message{
    border: 0 none;
    font-size: 100%;
    margin: 0;
    padding: 3px 0;
    width: 100%;
	height:108px;
	max-height:702px;
}

.forum_subs{
	margin: 5px 0 0;
	color:#666666;
	font-size:11px;
}
.forum_subs a{
	text-decoration:underline;
	color:#003399;
}
.subforumshead{
	font-size:12px;
	font-weight:bold;
	color:#333333;
}

/* ========================================================================== */
/* ========================== ПОЛЬЗОВАТЕЛИ ================================== */
/* ========================================================================== */
.add_file_link{
    height:18px;
    line-height:18px;
    padding-left:22px;
    background:url(../images/icons/users/upload.gif) no-repeat left center;
}
#usr_links{
	margin-top:0px;
	margin-bottom:10px;
	overflow:hidden;
	clear:both;
}

#usr_links sup{
    font-size:12px;
    color:#729CCC;
}

#usr_files, #usr_blog, #usr_board, #usr_forum, #usr_comments{
	display: inline-block;
	height:32px;
	line-height:32px;
    padding: 0 12px 0 35px;
	font-size:17px;
	margin:5px 0;
    margin-left:0px;
    float:left;
}

.online{
    padding:1px 5px;
	background-color:#00CC00;
	color:#FFF;
    border-radius:2px;
	font-size:11px;
}

.cmm_votes{
	float: right;
	padding: 2px;
    margin-right:15px;
    color:silver;
    font-weight:bold;
}
.cmm_good{
    color:green;
}
.cmm_bad{
    color:red;
}

#users_search_link a{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/search.png) no-repeat;
    text-decoration:none;
}

#users_search_link a span{
    border-bottom:dashed 1px #375E93;
}

#users_sbar{
    padding:15px;
    border-radius:4px;
    border:1px solid #C3D6DF;
    color:#375E93;
    background:#E0EAEF;
    margin-bottom:10px;
}

#users_sbar input[type=text] { border:1px solid #4DA4CF; padding:2px;}
#users_sbar .field{ width:150px; }
#users_sbar .longfield{ width:408px; }
#users_sbar p{ margin-top:10px; }

.users_search_results{
    padding:15px;
    border-radius:4px;
    border:1px solid #C3D6DF;
    color:#375E93;
    background:#E0EAEF;
    margin-bottom:15px;
}

.users_search_results h3{ font-size:18px; font-weight:normal; margin: 0 0 10px 0; }
.users_search_results ul,
.users_search_results li{ margin-left:0px;}

div.users_list_buttons{ overflow: hidden; }
div.users_list_buttons .button{
    border-radius:4px;
    display:block;
    float:left;
    font-size:18px;
    margin-right:15px;
    padding:5px 0px;
}

div.users_list_buttons .button a { color:#375E93; }

div.users_list_buttons .selected{
    background:#E0EAEF;
    padding:5px 10px;
    margin-right:15px;
}

div.users_list_buttons .selected a{
    text-decoration:none;
    color:#000000;
}

div.users_list {
    margin:15px 0px; padding:15px; border:1px solid #C3D6DF;
    border-radius:4px;
}

table.users_list div.avatar{ border:1px solid #C3D6DF; padding:2px; width:63px; height:63px; margin-bottom:10px; }
table.users_list div.number{ color:#999; }
table.users_list div.nickname { font-size:18px; }
div.microstatus {
    color:#5F98BF; font-size:11px;
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/comment-small.png) no-repeat left top;
    clear:both;
    margin-top:3px;
}
.microstatus {
    color:#5F98BF; font-size:11px;
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/comment-small.png) no-repeat left top;
    margin-top:3px;
}
table.users_list div.karma,
table.users_list div.rating{
    font-size:12px;
    float:right;
    overflow:hidden;
    padding:2px 5px;
    border-radius:4px;
    background:#E0EAEF;
}
table.users_list div.karma{
    margin-right:5px;
}
table.users_list .pos{
    color: green;
}
table.users_list .neg{
    color: red;
}

table.users_list div.city { clear:both; }
table.users_list div.city a{ color:#666; }
table.users_list div.status { float:right; padding:2px; margin-right:5px; }
table.users_list div.status .online{
    padding:1px 5px; background-color:#00CC00; color:#FFF; font-size:11px;
    border-radius:2px;
}
table.users_list div.status .offline{ color:#999999; font-size:11px; background:none; padding:0px;}

#mod_user_stats div.stat_block { margin-left:15px; }
#mod_user_stats div.stat_block div.title {
    color:#1873AA;
    font-size:16px;
    margin-bottom:0px;
    text-shadow:0 2px 0 #FFFFFF;
    font-weight:bold;
}
#mod_user_stats div.stat_block div.body ul { list-style:none; margin:0px; margin-left:20px; padding:0px; }
#mod_user_stats div.stat_block div.body li { list-style:none; margin:0px; margin-bottom:3px; }

#mod_user_stats div.stat_block_bday { margin-left: 20px;    }
#mod_user_stats div.stat_block_bday div.title { text-shadow:0 2px 0 #FFFFFF; padding-left:18px; background:url(../images/icons/cake.png) no-repeat left center; font-size:16px; color:#1873AA;; font-weight:bold; margin-bottom:5px; margin-top:20px;}

div#user_ratings { float:right; }

div#user_ratings div.karma, div#user_ratings div.rating {
    float:left; text-align:center;
}
div#user_ratings .value,  div#user_ratings .value-positive, div#user_ratings .value-negative{
    font-size:14px; padding: 4px; margin: 2px;
    border-radius:10px;
    cursor:pointer;
}
div#user_ratings .karma .value-positive { background-color:#427F00; color:#FFF; }
div#user_ratings .karma .value-negative { background-color:#BF2F17; color:#FFF; }

div#user_ratings .karma table td { font-size:14px; }

div#user_ratings .rating .value {  background-color:#4A73A7; color:#FFF; padding:6px 10px; }

.user_group_name{
    float:right;
    margin-right:20px;
    height:38px;
    line-height:38px;
}

.user_group_name div {
    padding-left:20px;
    background-position: left center;
    background-repeat: no-repeat;
    color:#375E93;
}

.user_group_name .admin { background-image:  url(../images/icons/groups/admin.png); }
.user_group_name .registered { background-image: url(../images/icons/groups/registered.png); }
.user_group_name .editors { background-image: url(../images/icons/groups/editors.png); }
.user_group_name .moderators { background-image: url(../images/icons/groups/moderators.png); }
.user_group_name .vip { background-image: url(../images/icons/groups/vip.png); }

div#user_profile_url{
    clear:both;
    display:block;
    padding:5px;
    margin-top:15px;
    text-align:center;
    border-radius:4px;
    background:none repeat scroll 0 0 #E0EAEF;
}
#user_profile_url div{ color:#375E93; }
#user_profile_url a{ color:#5F98BF; }

div.usr_profile_menu{ margin-top:10px; clear:both; overflow:hidden; }

div.usr_profile_menu a { padding:4px; border: solid 1px #FFF; overflow:hidden; float:left; }


a.select-avatar{
    height:16px;
    line-height:16px;
    padding-left:18px;
    background:url(../images/icons/users/selectavatar.gif) no-repeat left center;
}

div.user_profile_data { padding:10px; padding-top:0px; padding-bottom:0px;}

.offline{
    padding:1px 3px;
    border-radius:2px;
	background-color: #DD6C6C;
	color:#FFF;
	font-size:11px;
}
.user_profile_data .offline, .user_profile_data .online, .user_profile_data .logdate{
	font-size:12px;
}
.logdate{ color:#999999; font-size:11px;}
.usr_friends_entry .offline{
	color:#999999;
}

div.user_profile_data .field{ overflow: hidden; clear:both; }

div.user_profile_data .field .title,
div.user_profile_data .field .value { float:left; line-height:24px; }

div.user_profile_data .field .title { width:170px; font-weight:bold; }

#usr_board{	background:url(../images/icons/big/board.png) no-repeat; }
#usr_files{	background:url(../images/icons/big/folder_ftp.png) no-repeat; }
#usr_blog{ background:url(../images/icons/big/blog.png) no-repeat; }
#usr_forum{ background:url(../images/icons/big/folder.png) no-repeat; }
#usr_comments{ background:url(../images/icons/big/faq_answer.png) no-repeat; }

#upr_feed .action_entry {
	border-bottom:1px solid #BFE5FF;
	margin-bottom:10px;
	overflow:hidden;
	padding-bottom:10px;
	padding-left:22px;
}

#upr_feed .action_title {
    font-size:12px;
    overflow:hidden;
    padding:2px 0;
}

.usr_friends_query{
	border-radius:4px;
    background-color:#FFFFFF;
    border:1px solid #65A1C2;
    padding:10px;
    margin-top:10px;
}
.usr_friends_query_one{ margin-bottom:5px; }
.usr_friends_query_yes{ height:16px; line-height:16px; padding-left:20px; background:url(../images/icons/yes.png) no-repeat; }
.usr_friends_query_no{ height:16px; line-height:16px; padding-left:20px; background:url(../images/icons/no.png) no-repeat; }



.content_title { font-size:14px; font-weight:bold; }

.usr_friends_feed{ padding:6px; }
.usr_friends_feed a.nickname { color:gray; }

.usr_com_title{	padding: 5px; background-color:#EBEBEB; border-top:solid 1px #666;	border-bottom:none; }
.usr_com_body{	padding-top: 15px; }

.usr_wall_entry {
    border-radius:4px;
    margin:5px 0;
    padding:5px 0px;
}
.usr_wall_avatar {
    border-radius:4px;
    border:1px solid #C3D6DF;
    padding:4px;
    background:#FFF;
}
.usr_wall_text{
    padding-left:10px;
    padding-top:10px;
}
.usr_wall_title{ float:left; color:#5F98BF; margin-bottom:10px; }
.usr_wall_delete { float:right; padding:5px; }
.usr_wall_delete a {
    background:url(../images/icons/delete.png) no-repeat left center;
    padding:1px;
    padding-left:20px;
    height:16px;
    line-height:16px;
    text-decoration: none;
    color:#375E93;
}

.usr_profile_block{
    clear:both;
    margin-top:10px;
    padding-top:10px;
    border-top:1px solid #C3D6DF;
    margin-left:10px;
}

.usr_msg_entry{
    padding:10px;
    border-radius:4px;
    margin-bottom:4px;
    border:solid 1px #C3D6DF;
}

.usr_msg_title{	padding: 4px 0; }
.usr_msg_title strong{ color:#375E93; }

.usr_msg_body{ padding: 10px; }

.usr_msg_date{
    color:#5F98BF;
}

.usr_publ_albums{ overflow:hidden;	background-color:#EBEBEB; padding:6px; margin-bottom:10px; }

.usr_article_add{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/add.png) no-repeat left center;
}
.usr_photo_add{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/addphoto.png) no-repeat left center;
}
.usr_del_album{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/deletecat.png) no-repeat left center;
    margin-left:6px;
    text-decoration: none;
}
.usr_edit_album{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/edit.png) no-repeat left center;
    margin-left:6px;
    text-decoration: none;
}

#usr_photos_upload_album{
    background:#E0EAEF;
    border-radius:4px;
    padding:10px;
    margin-bottom:5px;
}

#usr_photos_upload_album .text-input{
    width:250px;
}
#usr_photos_upload_album .select-input{
    width:257px;
}

.photo_desc{
    text-align: left;
    color:#5F98BF;
}

.usr_photos_add_limit{
    margin-bottom: 20px; color:#5F98BF;;
}

.usr_photos_submit_one{
    padding:10px;
    border:solid 1px #C3D6DF;
    border-radius:4px;
    margin-bottom:2px;
}
.usr_photos_submit_one .ph_thumb{
    border: solid 3px #E0EAEF;
    border-radius:3px;
    width:96px;
    padding:2px;
}
.usr_photos_submit_one .text-input{
    width:300px;
}
#usr_photos_submit_btn{
    margin-top:15px;
    color:#5F98BF;
}
#usr_photos_submit_btn input{
    font-size:18px;
}

.usr_photo_thumb{
    padding:1px;
    padding-top:10px;
    float:left;

    width:120px;
    display:block;
    margin:4px;
    margin-left:0px;
    border-radius:4px;
    border:1px solid #C3D6DF;
    text-align:center;
}
.usr_photo_date{ font-size:11px; color:#5F98BF; display:block; }
.usr_photo_hits{ font-size:10px; color:#999; display:block; }

.usr_photos_notice{
    margin:10px 0px;
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/info.png) no-repeat left center;
	clear:both;
}

.usr_photos_add_st{
    margin-top:20px;
    width:500px;
    color:#5F98BF;
}

.usr_photo_sel_bar{
    margin:10px 0px;
    display:inline-block;
}

.usr_photo_prev_link,
.usr_photo_next_link{
    display:inline-block;
    width:32px;
    height:32px;
    margin:0px 15px;
}

.usr_photo_prev_link { background:url(../images/icons/big/prev.png) no-repeat; float:right; }
.usr_photo_next_link { background:url(../images/icons/big/next.png) no-repeat; float:left; }

.usr_photo_link_edit,
.usr_photo_link_delete{
    padding:1px;
    padding-left:20px;
}

.usr_photo_link_edit { background:url(../images/icons/edit.png) no-repeat left center; }
.usr_photo_link_delete { background:url(../images/icons/delete.png) no-repeat left center; margin-left:10px;}

.usr_photo_view{ text-align:center; padding:10px; background-color:#FFFFFF; }
.usr_editprof{	background-color:#FFFFFF; border:solid 1px gray; padding:8px; width:100%; }
.usr_edithint{	color:#666666;	font-size:10px; }

.usr_q_title{ font-weight:bold; margin:1px;	margin-bottom:12px;	padding:5px; clear:both; border-bottom:dashed 1px gray; }

.usr_q_thumb{ margin-left:1px; margin-bottom:1px; float:left; display:block; width:70px; }
.usr_q_thumbimg{ background-color:#FFFFFF; border:solid 1px gray; }
.usr_q_link{ color:#003366; }

.usr_award_block{ display:block; margin:2px; width:100%; }
.usr_award_date{ font-size:10px; color:#999999;	margin-top:5px; }

.usr_deleted{ font-size:18px; color:#990000; }
.usr_restore{ margin-top:10px; }

.usr_btnlink{ margin-right:20px; font-size:24px;	 }

.usr_files_orderbar{ background-color:#EBEBEB;	margin-bottom:4px;	padding:5px; }
.usr_files_orderbtn{ font-size:9px;	background-color:#CCCCCC; border:solid 1px gray; height:20px; }
.usr_files_link{ font-size:10px; color:#999999; }

.usr_wall{
    margin-top:5px; overflow:hidden; clear:both;
}
.usr_wall #addwall{	padding-right: 10px;}

.usr_wall_header{ font-size:18px; color:#375E93; margin-bottom:5px; }
.usr_wall_body{ margin-bottom:10px;	}
.usr_wall_body p{
    padding:2px;
    margin:5px 0px;
    color:#5F98BF;
    font-size:14px;
}
.usr_wall_addlink{
    border-radius:4px;
    padding:5px 8px;
	padding-left:25px;
    display:inline-block;
	background:url(../images/icons/add.png) no-repeat 6px center #E0EAEF;
}
.club_wall_addlink{
    padding:0 0 0 25px;
	float:right;
	background:url(../images/icons/add.png) no-repeat 6px center #E0EAEF;
}
.usr_wall_addlink a, .club_wall_addlink a { color:#375E93; text-decoration:none; font-size:13px; }
.usr_friendslink{ text-decoration:underline; color:#999999; }

#karmalink{	font-size:16px;	font-weight:bold; padding:4px; background-color:#EBEBEB; margin:2px; margin-left:8px; }
#usertitle{	overflow:hidden;}
#nickname{	margin:0px; }

.usr_group{
	padding:2px;
	padding-left:20px;
	margin-top:5px;
	margin-bottom:5px;
	background-repeat:no-repeat;
	line-height:16px;
	color:#666666;
}
.usr_awlist_link{
	font-size:14px;
	background:#ccc;
	padding:5px;
	text-align:center;
	font-weight:bold;
}

.usr_awlist_link a{	text-decoration:none; }
.usr_aw_dettable{ margin:5px;	margin-bottom:20px; }
.usr_aw_img{ padding:5px; border:solid 1px #ccc; }
.usr_aw_title{ font-size:18px; color:#375E93; }
.usr_aw_desc{ font-size:14px; color:#5F98BF; }
.usr_aw_who{ padding:10px; }

.user_gender_m, .user_gender_ { background:url(../images/icons/male.png) no-repeat 0px 2px;	padding-left:20px;	line-height:18px; }
.user_gender_f { background:url(../images/icons/female.png) no-repeat 0px 2px; padding-left:20px; line-height:18px; }

.usr_aw_users{ margin-bottom:4px; color:#375E93; }

div.awards_list_link { padding:5px 0px; }
div.awards_list_link a{ padding:1px; padding-left:20px;  background:url(../images/icons/info.png) no-repeat left center; }

#usr_profparams{ width:750px; border:dashed 1px silver;	padding:10px; }

#usr_profsavebtns{	background:#EBEBEB;	width:775px; margin-top:10px; margin-bottom:5px; text-align:center; }

#usr_prof_form{	padding:10px;padding-top:0px; }

#usr_prof_form em{ color:#999; }

#usr_prof_form #title{ width:170px; height:24px; line-height:24px; }
#usr_prof_form #field{ height:24px; line-height:24px; }

#usr_prof_form table{ width:100%; }

.usr_msgmenu_tabs{ display:block; height:37px; clear: both; margin: 20px 0 0; overflow: hidden; }

.usr_msgmenu_active{
    margin-right:0px;	background-color:#E0EAEF; padding:10px;	font-weight:bold;
    border-radius:4px 4px 0 0; float: left;
}
.usr_msgmenu_link{	margin-right:0px;	padding:10px; float: left; }

.in_link{ padding-left:26px; background:url(../images/icons/users/message-in.png) no-repeat 6px center #FFF; }
.in_span{ padding-left:26px; background:url(../images/icons/users/message-in.png) no-repeat 6px center #E0EAEF; }

.out_link{ padding-left:26px; background:url(../images/icons/users/message-out.png) no-repeat 6px center #FFF; }
.out_span{ padding-left:26px; background:url(../images/icons/users/message-out.png) no-repeat 6px center #E0EAEF; }

.notices_link{ padding-left:26px; background:url(../images/icons/users/message_notice.png) no-repeat 6px center #FFF; }
.notices_span{ padding-left:26px; background:url(../images/icons/users/message_notice.png) no-repeat 6px center #E0EAEF; }

.new_link{ padding-left:26px; background:url(../images/icons/users/message-new.png) no-repeat 6px center; text-decoration: none; }

.history_link{ padding-left:26px; background:url(../images/icons/users/message_history.png) no-repeat 6px center #FFF; }
.history_span{ padding-left:26px; background:url(../images/icons/users/message_history.png) no-repeat 6px center #E0EAEF; }

a.msg_delete:hover,
a.msg_reply:hover,
a.msg_history:hover{
    color:#5F98BF;
}

a.msg_delete,
a.msg_reply,
a.msg_history,
span.msg_new{
    color:#375E93;
    height:16px;
    line-height:16px;
    padding:1px 1px 1px 20px;
    text-decoration:none;
}

a.msg_reply,
a.msg_history,
span.msg_new{
    margin-right:10px;
}

a.msg_delete{ background:url("../images/icons/delete.png") no-repeat left center; }
a.msg_reply{ background:url(../images/icons/users/message_reply.png) no-repeat left center; }
a.msg_history{ background:url(../images/icons/users/message_history.png) no-repeat left center; }
span.msg_new{ background:url(../images/icons/new.png) no-repeat left center; color:red; }

table.feed { margin-top:10px; margin-bottom:10px; }
table.feed td { font-size:14px; }
table.feed td.date { font-size:12px; text-align:left; width:110px; }
table.feed td.date strong { color:green; }

.usr_files_head{
    background-color:#ECECEC;
    font-weight:bold;
    padding:4px;
}

.usr_status_bar{
    clear:both;
    margin-top:0px;
    padding-bottom:10px;
}

.usr_status_text{
    color:#5F98BF;
    background:url(../images/icons/comment.png) no-repeat left center;
    padding-left:20px;
    float:left;
}

.usr_status_date{
    font-size:10px;
    color:gray;
    font-style:normal;
    margin-left:10px;
    margin-right:14px;
}

.usr_status_link{
    float:left;
}

.usr_status_link a{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/edit.png) no-repeat left center;
}

.usr_avatar{
    padding:10px;
    border:solid 1px #65A1C2;
    background-color:#FFFFFF;
    border-radius:4px;
}

.usr_avatars_lib_link{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/images.png) no-repeat left center;
}

.usr_friends_block{
    overflow:hidden;
    margin-bottom:15px;
}

.usr_friend_cell{
    padding-right:10px;
    margin-right:5px;
    margin-bottom:5px;
}

.usr_friend_cell .friend_link{
    font-size:14px;

}

.usr_friend_cell span{ font-size:11px; }
.usr_friend_cell span.offline{ color:#5F98BF; background:none; }

.usr_albums_list{
    margin:10px 0;
    padding:0;
    clear:both;
    overflow:hidden;
}

.usr_albums_list li{
    margin:0 10px 10px 0;
	padding:0;
    display: block;
    float:left;
    width:200px;
	height:75px;
}
.usr_albums_list li.no_albums{
	height:auto;
}

.usr_album_thumb{
    display:block;
    float:left;
    width:64px;
    height:64px;
    margin-right:10px;
    padding:2px;
    border:1px solid #65A1C2;
}

.usr_album .link{ font-size:14px; }
.usr_album .count { color:#5F98BF; }
.usr_album .date { color:#AAA; font-size:11px; }



/* ========================================================================== */
/* ======================= ЛЕНТА АКТИВНОСТИ ================================= */
/* ========================================================================== */
.action_friends{
	position:relative;
	margin:0 0 10px 0;
	border-collapse: separate;
}
.action_friends td {
    background-color: #ECF2F4;
	border-left:1px #ECF2F4 solid;
	border-right:1px #ECF2F4 solid;
	border-top:1px #ECF2F4 solid;
    padding: 5px 0;
	vertical-align: middle;
	text-align:center;
}
.action_fr {
	vertical-align: middle;
}
.action_friends td.selected {
    background-color: #FFFFFF;
	border-color:#BFE5FF;
}
.arr_btn {
    text-decoration: none;
	font-size:40px;
}
.mod_act_all{
    padding:1px;
    padding-left:20px;
    background:url(../images/icons/actions.png) no-repeat;
    color:#333;
}
.actions_list{
    clear:both;
}
.actions_list h3 {
    color: #000;
}
.action_entry {
    border-bottom: 1px solid #ecf0f1;
    margin-bottom: 15px;
    padding: 0 0 5px 25px;
}

.action_entry .is_new { color:#FF5A00; }

.action_user{
    color:#375E93;
    font-weight:bold;
}

.action_title{
    font-size:14px;
}

.action_date{
    float:right;
    margin-left: 15px;
    margin-bottom: 15px;
    font-size:11px;
    color:#5F98BF;
}

.action_details{
    clear:both;
    color:gray;
	overflow:hidden;
}

.action_details .act_photo{
    overflow:hidden;
    display:inline-block;
}

.act_add_comment{ background:url(../images/icons/actions/add_comment.png) no-repeat left 3px; }
.act_add_photo, .act_add_photo_club{ background:url(../images/icons/actions/add_photo.png) no-repeat left 3px; }
.act_add_article{ background:url(../images/icons/actions/add_article.png) no-repeat left 3px; }
.act_set_status{ background:url(../images/icons/actions/set_status.png) no-repeat left 3px; }
.act_add_friend{ background:url(../images/icons/actions/add_friend.png) no-repeat left 3px; }
.act_add_blog{ background:url(../images/icons/actions/add_blog.png) no-repeat left 3px; }
.act_add_post{ background:url(../images/icons/actions/add_post.png) no-repeat left 3px; }
.act_add_board{ background:url(../images/icons/actions/add_board.png) no-repeat left 3px; }
.act_add_catalog{ background:url(../images/icons/actions/add_catalog.png) no-repeat left 3px; }
.act_add_club{ background:url(../images/icons/actions/add_club.png) no-repeat left 3px; }
.act_add_club_user{ background:url(../images/icons/actions/add_club_user.png) no-repeat left 3px; }
.act_add_quest{ background:url(../images/icons/actions/add_quest.png) no-repeat left 3px; }
.act_add_fpost{ background:url(../images/icons/actions/add_fpost.png) no-repeat left 3px; }
.act_add_thread{ background:url(../images/icons/actions/add_thread.png) no-repeat left 3px; }
.act_add_avatar{ background:url(../images/icons/actions/add_avatar.png) no-repeat left 3px; }
.act_add_award{ background:url(../images/icons/actions/add_award.png) no-repeat left 3px; }
.act_add_file{ background:url(../images/icons/actions/add_file.png) no-repeat left 3px; }
.act_add_wall{ background:url(../images/icons/actions/add_wall.png) no-repeat left 3px; }
.act_add_wall_club{ background:url(../images/icons/actions/add_wall_club.png) no-repeat left 3px; }
.act_add_post_club{ background:url(../images/icons/actions/add_post_club.png) no-repeat left 3px; }
.act_add_user_photo{ background:url(../images/icons/actions/add_user_photo.png) no-repeat left 3px; }
.act_add_user_photo_multi{ background:url(../images/icons/actions/add_user_photo_multi.png) no-repeat left 3px; }
.act_add_user{ background:url(../images/icons/actions/add_user.png) no-repeat left 3px; }
.act_add_wall_my{ background:url(../images/icons/actions/add_wall_my.png) no-repeat left 3px; }

/** Core messages styles [AM][start] **/
.button_yes, .button_no {
    background: none repeat scroll 0 0 transparent;
    border: 1px solid;
    cursor: pointer;
    font-family: tahoma,verdana,arial,sans-serif,Lucida Sans;
    font-size: 11px;
    margin: 3px;
    outline: medium none;
    overflow: visible;
    padding: 4px 14px;
    white-space: nowrap;
}
.button_yes::-moz-focus-inner, input.button_no::-moz-focus-inner {
    border: 0 none;
}
.button_yes {
    background: none repeat scroll 0 0 #5D81A7;
    border: 1px solid #45688E;
}
.button_yes {
    background: url('../images/core/button.png') repeat-x scroll 0 -16px #6181A6;
    border-color: #789ABF #5980A9 #5980A9;
    color: #FFF;
    text-shadow: 0 1px 0 #45688E;
}
.button_yes:hover {
    background-position: 0 -8px;
}
.button_yes:active {
    background: none repeat scroll 0 0 #5E80A5;
    border: 1px solid #5E80A5;
    position: relative;
}
.button_no {
    background: none repeat scroll 0 0 #E0E0E0;
    border: 1px solid #B3B3B3;
}
.button_no {
    background: url('../images/core/button.png') repeat-x scroll 0 -54px #DDDDDD;
    border-color: #FFFFFF #E7E7E7 #E5E5E5;
    color: #444444;
    text-shadow: 0 1px 0 #FFFFFF;
}
.button_no:hover {
    background-position: 0 -46px;
}
.button_no:active {
    background: none repeat scroll 0 0 #DCDCDC;
    border: 1px solid #DCDCDC;
    position: relative;
}
#popup_container {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    text-align: left;
    padding: 0;
    margin: 0;
}
#popup_overlay {
    position: absolute;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    background: #666;
    opacity: 0.4;
}
#popup_container .popup_body {
    background: #fff;
    width: 650px;
    margin: 0 auto;
    box-shadow: 0 2px 8px #666;
    overflow: hidden;
}
.popup_body > div {
    clear: both;
}
#popup_title,
#note_title {
    background: none repeat scroll 0 0 #45688E;
    border-bottom: 1px solid #666;
    color: #FFF;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 7px;
    text-shadow: 0 1px 2px #333;
}
#popup_container .popup_x_button,
#noteform .popup_x_button {
    background: url("../images/core/close_btn.png") no-repeat center center;
    cursor: pointer;
    float: right;
    height: 16px;
    margin: 3px 3px 0 0;
    opacity: 0.7;
    padding: 3px;
    width: 16px;
}
#popup_container .popup_x_button:hover,
#noteform .popup_x_button:hover {
    opacity: 1.0;
}
#popup_progress {
    display: none;
    text-align: center;
    width: 100%;
    margin: 45px 0 28px;
}
#popup_content {
    padding: 0;
    margin: 0;
}
#popup_message {
    font-size: 13px;
    margin: 7px;
}
#popup_message .box_message_content {
    font-size: 13px;
    padding: 5px;
}
#popup_panel, #note_panel {
    background: #f9f9f9;
    border-top: 1px solid #ccc;
    padding: 3px;
    padding-right: 10px;
    text-align: right;
    overflow: hidden;
}
#popup_prompt {
    margin: 0.5em 0
}
span.ajax-loader {
    background: url('../images/core/ajax-loader.gif') no-repeat;
    display: none;
    float: left;
    left: 6px;
    position: relative;
    top: 11px;
    width: 36px;
}
#popup_info {
    border: 1px solid #666666;
    display: none;
    float: left;
    margin: 3px 2px;
    padding: 2px 9px 1px;
    position: relative;
}
#popup_info.error {
    background-color: #E6A4A4;
}
#popup_info.info {
    background-color: #AEFFA4;
}
/** Core messages styles [AM][end] **/

#popup_message div.js_smiles, #smileajax {
    height: 1px;
}
#popup_message div.js_smiles a, #smileajax a {
    text-decoration: none;
}

/** Popup smile and bb-code panel [AM][end] **/

/** Ajax intive club [AM][start] **/
.clearFix:after {
    content :".";
    display :block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.friend_list_top {
    background-color: #F7F7F7;
    padding: 4px 4px 0 4px;
    font-size: 12px;
	overflow:hidden;
	margin: 0 -3px;
	border-bottom: 1px solid #AAAAAA;
}
*:first-child+html .friend_list_top {
    padding-bottom: 18px;
}
#friend_list_lookup {
    float: right;
    width: 180px;
    color: #777;
}
.friend_list_body {
    padding: 4px 0px 0px 10px;
    width: 657px;
    max-height: 500px;
    border-top: 0px solid #c3edb2;
    overflow-x: hidden;
    overflow-y: scroll;
}
.friend_list_body_ajax {
    padding: 4px 0 0 3px;
    width: 100%;
    height: 276px;
    border-top: 0px solid #c3edb2;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #FFF;
    font-size: 10px;
}
.flist_cell,
.flist_cell_on {
    float: left;
    border-bottom: 1px solid #FFF;
    margin: 0 3px 2px 0;
    cursor: pointer;
}
.flist_cell:hover {
    border-bottom: 1px solid #F1F9FE;
}
.flist_cell_on {
    border-bottom: 1px solid #C0C0C0;
}
.flist_cell .flist_border_wrap {
    border: 1px solid #FFF;
}
.flist_cell:hover .flist_border_wrap {
    background-color: #F1F9FE;
    border: 1px solid #C0C0C0;
}
.flist_wrap {
    border: 1px solid #FFF;
    width: 143px;
    height: 56px;
    padding: 3px;
}
.flist_div {
    margin-right: 3px;
    float: left;
    border: 1px solid #C0C0C0;
    background-color: #FFF;
}
.flist_cell:hover .flist_div {
    border: 1px solid #ADADAD;
}
.flist_image {
    margin: 2px;
    overflow: hidden;
    height: 50px;
    width: 50px;
}
.flist_name {
    margin-left: 3px;
    overflow: hidden;
    float: left;
    word-wrap: break-word;
    width: 68px;
    color: #000;
}
.flist_cell_on .flist_name {
    color: #FFF;
}
.flist_cell em,
.flist_cell_on em,
.flist_cell:hover em {
    background-color: #c3b2ed;
    color: #000;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
}
.flist_cell_on em {
    background-color: #FFF;
    color: #F00;
}
.flist_cell:hover em {
    background-color: #C0C0C0;
}
.flist_empty {
    background-color: #FFF;
    color: #808080;
    font-size: 12px;
    text-align: center;
    padding: 103px 0 90px 0;
    margin: 10px 10px 12px 10px;
}
.flist_more {
    padding: 10px;
    text-align: center;
    cursor: pointer;
}
.t_filter_area {
    border-bottom: 1px solid #c3edb2;
    padding: 10px;
    background-color: #F7F7F7;
    _padding-bottom: 18px;
}
*:first-child+html .t_filter_area {
    padding-bottom: 18px;
}
.t_filter_selected {
    float: left;
    border-bottom: 1px solid #F7F7F7;
    margin-right: 10px;
    margin-bottom: 2px;
    cursor: pointer;
}
.t_filter_selected .t_filter2 {
    border: 1px solid #ccc;
}
.t_filter_off {
    cursor: pointer;
    padding: 4px 7px 5px;
    color: #368E3A;
    font-weight: bold;
    float: left;
    margin-right: 10px;
    margin-bottom: -1px;
}
.t_filter_selected .t_filter3 {
    border: 1px solid #396EA4;
    padding: 2px 9px 1px;
    background-color: #396EA4;
    color: #F7F7F7;
    font-weight: bold;
}
.t_filter_off {
    cursor: pointer;
    padding: 4px 7px 5px;
    color: #111;
    font-weight: bold;
    float: left;
    margin-right: 10px;
    margin-bottom: -1px;
}
.flist_cell_on .flist_border_wrap {
    border: 1px solid #111;
    background: #395fa7;
    opacity: 0.7;
    filter: alpha(opacity=70);
}
.club-intive-mess {
    background-color: #111;
    display: none;
    width: 360px;
    height: 27px;
    position: relative;
    top: -33px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: -37px;
    font-weight: bold;
    color: #fff;
    font-size: 12px;
}
.club-intive-mess-ajax {
    background-color: #111;
    display: none;
    width: 470px;
    height: 51px;
    position: absolute;
    bottom: 160px;
    text-align: center;
    padding-top: 26px;
    font-weight: bold;
    color: #fff;
    font-size: 18px;
}
/** Ajax intive club [AM][end] **/
#langs {
    background: none no-repeat scroll 5px center #375E93;
    border: 1px solid #5D90D1;
    color: #FFF;
    cursor: pointer;
    float: right;
    height: 28px;
    line-height: 28px;
    margin: 15px 0 0 15px;
    padding: 0 0 0 27px;
    position: relative;
    font-size: 10px;
    color: #CCC;
    width: 17px;
}
#langs:hover, #langs.active_lang, .active_lang {
    background: none no-repeat scroll 5px center #5D90D1;
    border: 1px solid #375E93;
}
#langs-select{
    display: none;
    position: absolute;
    list-style: none;
    top: 22px;
    left: -1px;
    padding: 0;
    border-top: none;
    color: #FFF;
    font-size: 12px;
    width: 44px;
}
#langs-select li {
    margin: 0;
    padding: 0 5px 0 25px;
    background: none no-repeat scroll 5px center #5D90D1;
}
.arhive_list {
    font-size: 16px;
    list-style: none outside none;
    padding: 0;
}
.arhive_list li {
    margin: 0;
}
.arhive_list li span {
    color: #555;
}
.arhive_date {
    background: none repeat scroll 0 0 #777;
    border-radius: 4px;
    float: right;
    font-size: 12px;
    margin: 15px 0;
    padding: 2px 4px;
}
.arhive_date a {
    color: #FFF;
}
.arhive_title {
    font-size: 18px;
    padding: 12px 0;
}
.arhive_title a {
    text-decoration: none;
}
.arhive_desc{
    clear: both;
}
.debug_info {
    margin: 10px;
    color: #CCC;
}
#debug_query_dump {
    display: none;
}
.debug_query_count a {
    color: #FFF;
    border-color: #FFF;
}
#debug_query_show .query {
    background: #FFF;
    border: 1px solid #DDD;
    border-radius: 3px;
    margin: 10px;
    padding: 10px;
}
#debug_query_show .query .src {
    color: #999;
    font-size: 12px;
    margin-bottom: 4px;
}
.query_time {
    color: #999;
    font-size: 12px;
    margin: 4px 0 0;
}
.red_query {
    color: #c0392b;
}
.green_query {
    color: #27ae60;
}
.filelink a {
    background: url('../images/icons/download.gif') no-repeat center left;
    padding: 0 0 0 20px;
}
.city_block{
    overflow: hidden;
    clear: both;
    background: #FFF;
}
.city_link{
    background: #68809B;
    float: right;
    margin: 0 1px;
    padding: 0 7px;
    text-decoration: none;
    color: #FFF;
    border-radius: 2px;
    line-height: 19px;
}
.city_link:hover {
    background: #B3C2C9;
    color: #333;
}
.city_view{
    border: none !important;
    margin: 0;
    padding: 1px 0;
}
.img_64 {
    width: 64px;
    height: 64px;
}
#similar_posts > ul {
    padding: 0;
}
#similar_posts li {
    clear: both;
    list-style: none;
    margin: 0 0 10px;
    overflow: hidden;
}
#similar_posts li:last-child {
    margin-bottom: 0;
}
#similar_posts li img {
    width: 100%;
}
#similar_posts li > a {
    float: left;
    margin: 0 10px 0 0;
    width: 100px;
}
#similar_posts h5 {
    font-size: 14px;
}
#similar_posts {
    background: #e0eaef;
    border-radius: 4px;
    padding: 0 10px 10px;
    overflow: hidden;
}
#similar_posts h4 {
    color: #333;
    margin: 10px 0;
}