@import '../vendor/bootstrap/less/bootstrap.less';
@sam-icon-arrow-box-left: "\e900";
@sam-icon-arrow-box-right: "\e901";
@sam-icon-arrow-thin-right: "\e902";
@sam-icon-time: "\e903";
@sam-icon-mail: "\e904";
@sam-icon-point: "\e905";
@sam-icon-home: "\e906";
@sam-icon-arrow-up: "\e907";
@sam-icon-arrow-down: "\e908";
@sam-icon-arrow-right: "\e909";
@sam-icon-arrow-left: "\e90a";
@sam-icon-menu: "\e90b";
@sam-icon-rub: "\e90c";
@sam-icon-search: "\e90d";
@sam-icon-instagram: "\e90e";
@sam-icon-phone: "\e90f";

@font-face {
    font-family: 'sam';
    src:    url('../fonts/sam/sam.eot?c3q0u4');
    src:    url('../fonts/sam/sam.eot?c3q0u4#iefix') format('embedded-opentype'),
        url('../fonts/sam/sam.ttf?c3q0u4') format('truetype'),
        url('../fonts/sam/sam.woff?c3q0u4') format('woff'),
        url('../fonts/sam/sam.svg?c3q0u4#sam') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Aksent';
	src: url('../fonts/Aksent/Aksent.eot');
	src: url('../fonts/Aksent/Aksent.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Aksent/Aksent.woff2') format('woff2'),
		url('../fonts/Aksent/Aksent.woff') format('woff'),
		url('../fonts/Aksent/Aksent.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'RobotoCondensed-Bold';
  src: url('../fonts/RobotoCondensed-Bold/RobotoCondensed-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/RobotoCondensed-Bold/RobotoCondensed-Bold.woff') format('woff'),
    url('../fonts/RobotoCondensed-Bold/RobotoCondensed-Bold.ttf') format('truetype'),
    url('../fonts/RobotoCondensed-Bold/RobotoCondensed-Bold.svg#RobotoCondensed-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('../fonts/RobotoCondensed-Regular/RobotoCondensed-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/RobotoCondensed-Regular/RobotoCondensed-Regular.woff') format('woff'),
    url('../fonts/RobotoCondensed-Regular/RobotoCondensed-Regular.ttf') format('truetype'),
    url('../fonts/RobotoCondensed-Regular/RobotoCondensed-Regular.svg#RobotoCondensed-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/Roboto-Regular/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'sam' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.sam-icon-arrow-box-left   { &:before { content: @sam-icon-arrow-box-left; } }
    &.sam-icon-arrow-box-right  { &:before { content: @sam-icon-arrow-box-right; } }
    &.sam-icon-arrow-thin-right { &:before { content: @sam-icon-arrow-thin-right; } }
    &.sam-icon-time             { &:before { content: @sam-icon-time; } }
    &.sam-icon-mail             { &:before { content: @sam-icon-mail; } }
    &.sam-icon-point            { &:before { content: @sam-icon-point; } }
    &.sam-icon-home             { &:before { content: @sam-icon-home; } }
    &.sam-icon-arrow-up         { &:before { content: @sam-icon-arrow-up; } }
    &.sam-icon-arrow-down       { &:before { content: @sam-icon-arrow-down; } }
    &.sam-icon-arrow-right      { &:before { content: @sam-icon-arrow-right; } }
    &.sam-icon-arrow-left       { &:before { content: @sam-icon-arrow-left; } }
    &.sam-icon-menu             { &:before { content: @sam-icon-menu; } }
    &.sam-icon-rub              { &:before { content: @sam-icon-rub; } }
    &.sam-icon-search           { &:before { content: @sam-icon-search; } }
    &.sam-icon-instagram        { &:before { content: @sam-icon-instagram; } }
    &.sam-icon-phone            { &:before { content: @sam-icon-phone; } }
}

header {
  background-repeat: repeat;
  background-position: left top;
  background-image: url("../images/spec-auto-market/bg-header.jpg");
  background-color: #355A8B;
  color: #fff;
  line-height: 1;
  .sam-sitename {
    position: relative;
    padding: 30px 0 40px;
    .sam-logo h1 {
      font-family: 'Aksent', sans-serif;
      font-size: 50px;
      margin: 0;
      padding: 0;
      line-height: 1;
      a { color: #fff; &:hover { text-decoration: none; } }
    }
    .sam-slogan {
      p {display: block; padding: 0; margin: 0; line-height: 1}
      position: absolute;
      top: 73px;
      left: 378px;
      text-transform: uppercase;
      letter-spacing: 1.1px;
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 12px;
    }
  }
  .sam-contacts {
    padding: 35px 0 30px;
    text-align: right;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    .sam-phones {
      p { margin: 0; padding: 0; line-height: 1; }
      font-size: 26px;
    }
    .sam-address {
      p { margin: 0; padding: 0; line-height: 1; }
      margin: 10px 0 0 0;
      font-size: 19px;
    }
  }
}

.sam-form-search {
  padding: 10px 0 0;
  margin: 0;
  overflow: hidden;
  input {
    width: 100px;
    margin: 0;
    padding: 0;
    background-color: inherit;
    border: none;
    border-bottom: #fff solid thin;
    font-size: 14px;
    color: #fff;
  }
  button {
    margin: 0;
    padding: 0;
    background-color: inherit;
    border: none;
    color: #fff;
    &:hover {background-color: inherit; color: #cc2f25;}
    i {
      display: block;
    }
  }
  .btn-sam-search {
    padding: 5px 0 0;
    font-size: 18px;
  }
}

.sam-instagram-link {
  margin: 0 0 0 20px;
  padding: 15px 0 0;
  a { color: #fff; &:hover { text-decoration: none; color: #cc2f25; } }
}

.sam-slider-main {
  position: relative;
  z-index: 0;
  .slide-item {
    height: 620px;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    .slide-caption {
      height: 620px;
      background-image: url("../images/spec-auto-market/bg-slider.png");
      background-position: right -230px top;
      background-repeat: no-repeat;
      .box-content {
        position: relative;
        height: 620px;
        .title {
          position: absolute;
          width: 470px;
          height: 167px;
          overflow: hidden;
          bottom: 65px;
          right: 0px;
          line-height: 1.3;
          text-align: right;
          font-size: 45px;
          color: #fff;
          a { display: block; color: inherit; &:hover { text-decoration: none; } }
        }
      }
    }
  }
  .slider-control {
    position: absolute;
    z-index: 1;
    top: 50%;
    background-color: #272727;
    line-height: 1;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    &.arrow-left { left: 0; padding: 5px 5px 3px 30px; }
    &.arrow-right { right: 0; padding: 5px 30px 3px 5px; }
    &:hover { background-color: #cc2f25; }
  }
}

.sam-row-1 {
  height: 430px;
  background-image: url('../images/spec-auto-market/bg-catalog-top.jpg');
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  .catalog-top {
    .catalog-top-item {
      padding: 65px 0 0 0;
      overflow: hidden;
      .box-image { width: 100%; height: 235px; overflow: hidden;}
      .catalog-top-item-image {
        display: block;
        width: 100%;
        height: 235px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        &:hover {
          transform: scale(1.1);
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1); /* IE 9 */
          -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand')"; /* IE8 */
           filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand'); /* IE6 and 7 */
        }
      }
      p {
        margin: 25px 0 0 0;
        line-height: 1.2;
        text-transform: uppercase;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 22px;
        a { color: #fff; &:hover { text-decoration: underline; } }
        i {
          margin: 0 -10px 0 30px;
          float: right;
          font-size: 45px;
          color: #345D91;
        }
      }
    }
  }
}

.sam-row-catalog {
  padding: 60px 0;
}
.sam-slider-catalog, .sam-catalog {
  .header {
    font-family: 'RobotoCondensed-Bold', sans-serif;
    .title {
      float: left;
      text-transform: uppercase;
      font-size: 22px;
    }
    .controls, .more { float: right; color: #D02929; }
    .controls { font-size: 30px; i { margin: 0 2px; cursor: pointer; &:hover { color: #85120c; }} }
    .more {
      margin: 0 15px 0 0;
      text-transform: uppercase;
      font-size: 16px;
      a { line-height: 38px; color: #D02929; &:hover { text-decoration: none; } }
    }
  }
  .box-content { overflow: hidden; }
  .items {
    clear: both;
    margin: 20px 0;
    .item-box {
      position: relative;
      overflow: hidden;
      border: #d4d4d4 solid thin;
      margin: 0 0 20px;
      padding: 20px 20px 70px;
      &:hover { border-color: #cc2f25; }
      .image {
        display: block;
        width: 100%;
        height: 210px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .title {
        overflow: hidden;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 18px;
        a { display: block; line-height: 1.2; color: #000; }
      }
      .description {
        overflow: hidden;
        margin: 0 0 25px;
        line-height: 1.2;
        font-family: 'Roboto-Regular', sans-serif;
        font-size: 14px;
      }
      .price {
        position: absolute;
        overflow: hidden;
        left: 0;
        bottom: 0;
        margin: 20px;
        i {
          display: block;
          float: left;
          padding: 10px;
          background-color: #880A16;
          font-size: 22px;
          color: #fff;
        }
        p {
          display: block;
          float: left;
          padding: 10px;
          margin: 0;
          background-color: #D2292C;
          line-height: 1;
          font-family: 'RobotoCondensed-Bold', sans-serif;
          font-size: 22px;
          color: #fff;
        }
      }
    }
  }
}
.sam-catalog {
  overflow: hidden;
  margin: 0 -10px 0;
  .items, .item-row { overflow: hidden; }
  .item {
    float: left;
    padding: 0 10px;
  }
}


.sam-row-3 {
  min-height: 483px;
  padding: 0 0 20px;
  background-image: url('../images/spec-auto-market/bg-about-transparant.png'),
    url('../images/spec-auto-market/bg-about.jpg');
  background-size: auto 100%, cover;
  background-repeat: no-repeat;
  background-position: left -250px top, center;
  color: #fff;
  .sam-about {
    line-height: 1.2;
    .box-container {
      overflow: hidden;
      margin: 60px 0 10px;
    }
    .title {
      margin: 0 0 35px;
      p { margin: 0; padding: 0; line-height: 1;}
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 22px;
    }
    .description {
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 14px;
      p { margin: 0 0 20px; }
    }
    .more {
      a {
        display: block;
        float: left;
        line-height: 1;
        padding: 10px 25px;
        border: #fff solid thin;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 16px;
        color: #fff;
         -webkit-transition: background-color ease 0.5s, color ease 0.5s;
         -moz-transition: background-color ease 0.5s, color ease 0.5s;
         -o-transition: background-color ease 0.5s, color ease 0.5s;
         transition: background-color ease 0.5s, color ease 0.5s;
        &:hover { color: #000; text-decoration: none; background-color: #fff; }
      }
    }
  }
}

.sam-row-5 {
  min-height: 445px;
  padding: 0 0 10px;
  background-image: url('../images/spec-auto-market/bg-news.jpg');
  background-repeat: repeat;
  background-position: left top;
  .news {
    .header {
      overflow: hidden;
      padding: 65px 25px 15px;
      background-color: #D1292C;
      p {
        display: block;
        margin: 0;
        padding: 0;
        line-height: 1;
        overflow: hidden;
        text-transform: uppercase;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 22px;
        color: #fff;
      }
    }
    .box-content {
      margin: 20px 0 0 0;
      .item { margin: 0 0 20px; }
      .box-image {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 210px;
        time {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          padding: 10px 25px;
          background-color: #D1292C;
          font-family: 'RobotoCondensed-Bold', sans-serif;
          font-size: 14px;
          color: #fff;
          z-index: 1;
        }
      }
      .image {
        display: block;
        overflow: hidden;
        height: 210px;
        margin: 0 0 15px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 1s ease;
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -webkit-transition: all 1s ease;
        -o-transition: all 1s ease;
        &:hover {
          transform: scale(1.1);
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1); /* IE 9 */
          -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand')"; /* IE8 */
           filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.5, M12=0, M21=0, M22=1.5, SizingMethod='auto expand'); /* IE6 and 7 */
        }
      }
      .caption {
        height: 210px;
        overflow: hidden;
        .title, .description {
          color: #fff;
          p { line-height: 1.16; margin: 0 0 15px; padding: 0; }
          a { color: #fff; }
        }
        .title {
          a { display: block; }
          font-family: 'RobotoCondensed-Bold', sans-serif;
          font-size: 20px;
        }
        .description {
          font-family: 'Roboto-Regular', sans-serif;
          font-size: 14px;
        }
      }
    }
    .more {
      float: right;
      a {
        text-transform: uppercase;
        text-decoration: underline;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 14px;
        color: #fff;
        &:hover { color: #cc2f25; }
      }
    }
  }
}

.sam-row-6 {
  padding: 0 0 40px;
  overflow: hidden;
  background-image: url('../images/spec-auto-market/bg-bottom.jpg');
  background-position: left top;
  background-repeat: repeat;
  .sam-row-box {
    margin: 65px 0 0 0;
    overflow: hidden;
    background-image: url('../images/spec-auto-market/bg-reviews-instagram.jpg');
    background-position: center top;
    background-repeat: no-repeat;
  }
  .header {
    text-transform: uppercase;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    font-size: 22px;
    p { display: block; margin: 0; padding: 12px 0; text-align: center; line-height: 1; }
    a { color: #fff; &:hover { text-decoration: none; }}
  }
  .instagram {
    .header { background-color: #D1292C; }
    .stats, .account {
      p {padding: 0; margin: 0; line-height: inherit; }
      overflow: hidden;
      line-height: 18px;
      font-family: 'Droid sans', sans-serif;
      font-size: 12px;
      color: #787878;
    }
    .account { float: right; font-size: 10px; a { color: inherit; } }
    .photos_count { float: left; }
    .followers { float: right; }
    .box-content {
      overflow: hidden;
      padding: 40px 0 0;
      .items {
        overflow: hidden;
        margin: 0 -3px;
        border-top: #303030 solid thin;
        border-bottom: #303030 solid thin;
        .item {
          float: left;
          padding: 6px 3px;
          width: 25%;
          p {padding: 0; margin: 0; line-height: inherit; }
          img { width: 100%; }
        }
      }
    }
   }
  .reviews {
    margin: 0 0 20px;
    .box-content {
      padding: 40px 0 0 0;
      line-height: 1.2;
      text-align: center;
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 14px;
      time {
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 14px;
      }
      .post {
        margin: 30px 0 0 0;
        a { color: #D1292C; &:hover { color: #2d5d91; text-decoration: none; } }
        p { margin: 0; }
      }
    }
    .name {
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 18px;
      p { margin: 0; }
    }
  }
}

.sam-row-7 {
  padding: 40px 0 85px;
  overflow: hidden;
  background-image: url('../images/spec-auto-market/bg-bottom.jpg');
  background-position: left top;
  background-repeat: repeat;
  .items { margin: 0 -15px; }
  .banner {
    float: left;
    width: 50%;
    /*height: 170px;*/
    padding: 15px;
    p {
      display: block;
      padding: 0;
      margin: 0;
      background-color: #888888;
      line-height: 1;
    }
    img { width: 100%; }
  }
}

.sam-row-8 {
  position: relative;
  overflow: hidden;
  height: 385px;
  .contacts-bg {
    position: absolute;
    z-index: 0;
    height: 385px;
    width: 50%;
    background-image: url('../images/spec-auto-market/bg-footer.png');
    background-position: right top;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .map {
    position: absolute;
    z-index: 0;
    height: 385px;
    width: 100%;
  }
  .footer-contacts {
    overflow: hidden;
    z-index: 1;
    min-width: 320px;
    max-width: 360px;
    padding: 80px 0 0;
    font-family: 'RobotoCondensed-Bold', sans-serif;
    color: #fff;
    p { margin: 0; padding: 0; line-height: 1; }
    a { color: #fff; }
    .header {
      margin: 0 0 40px;
      text-transform: uppercase;
      font-size: 24px;
    }
    .marker {
      float: left;
      margin: 0 30px 0 0;
      font-size: 16px;
      color: #D1292C;
    }
    .text { overflow: hidden; }
    .item {
      margin: 0 0 20px;
      font-size: 20px;
    }
  }
}

.page-contacts {
  .box-container {
    padding: 30px;
    background-color: #D1292C;
    color: #fff;
    p { margin: 0; padding: 0; line-height: 1; }
    a { color: inherit; }
    .header {
      font-family: 'RobotoCondensed-Bold', sans-serif;
      margin: 0 0 40px;
      text-transform: uppercase;
      font-size: 24px;
    }
    .marker {
      float: left;
      margin: 0 30px 0 0;
      font-size: 16px;
    }
    .text { overflow: hidden; }
    .item {
      margin: 0 0 20px;
      font-size: 17px;
    }
  }
  .contact-form {
    .header {
      p {margin: 0; padding: 0; line-height: inherit; }
      padding: 30px 0;
      text-transform: uppercase;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 20px;
    }
    .btn-sam {
      padding: 10px 30px;
      background-color: #D1292C;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 18px;
      color: #fff;
      -webkit-transition: background-color ease 0.5s, color ease 0.5s;
      -moz-transition: background-color ease 0.5s, color ease 0.5s;
      -o-transition: background-color ease 0.5s, color ease 0.5s;
      transition: background-color ease 0.5s, color ease 0.5s;
      &:hover { text-decoration: none; background-color: #000; }
    }
  }
  .google-map {
    margin: 30px 0 0;
  }
}

footer {
  background-color: #272727;
  min-height: 70px;
  text-transform: uppercase;
  color: #fff;
  a { color: #fff; &:hover { text-decoration: none;}}
  .copy { line-height: 70px; p { margin: 0; } }
  .outs {
    p { float: left; padding: 0 10px; margin: 0; line-height: 1; }
    text-align: right; padding: 20px 0;
  }
}

.content {
  padding: 0 0 60px;
}

.catalog {
  padding: 30px 0 0;
  ul {
    list-style: none;
  }
  a { color: inherit; }
  p {margin: 0; padding: 0; line-height: 1.2}
  .selected a { color: #D1292C; }
  > ul {
    padding: 0;
    line-height: 26px;
    font-size: 18px;
    ul {
      margin: 0 0 0 20px;
      padding: 10px 0 0 0;
      border-top: #D1292C solid thin;
      line-height: 20px;
      font-size: 16px;
    }
  }
  .marker {
    clear: left;
    float: left;
    margin: 0 10px 0 0;
    padding: 7px 0;
    font-size: 12px;
    color: #D1292C;
    span {
      cursor: pointer;
    }
  }
  .item {
    overflow: hidden;
    padding: 5px 0;
    &.folder {
      padding: 5px 0 10px;
    }
  }
}

.sam-catalog-photos {
  .sam-photo {
    display: block;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .sam-catalog-item-slider {
    width: 130px;
    float: right;
  }
  .control {
    overflow: hidden;
    line-height: 1;
    text-align: center;
    font-size: 18px;
    color: #355A8D;
    cursor: pointer;
    &.up { padding: 0 0 10px; }
    &.down { padding: 10px 0 0; }
  }
  .items {
    .item {
      overflow: hidden;
      .image {
        display: block;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
  }
}

.sam-catalog-item {
  .sam-item-properties {
    font-family: 'Roboto-Regular', sans-serif;
    color: #222;
    p {padding: 0; margin: 0;}
    .title { float: left; }
    .value { float: right }
    .sam-vendor-code {
      overflow: hidden;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 18px;
    }
    .items { padding: 35px 0 0; }
    .sam-item-property {
      overflow: hidden;
      padding: 10px 0;
      border-bottom: #222 thin dotted;
      font-size: 16px;
    }
  }
  .description {
    .header {
      padding: 15px 0;
      font-family: 'Roboto-Regular', sans-serif;
      font-weight: bold;
      font-size: 18px;
      p { margin: 0; padding: 0; line-height: 1; }
    }
    .box-content {
      padding: 20px;
      border: #d5d5d5 solid thin;
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 14px;
      p { margin: 0 0 20px; }
    }
  }
  .price {
    overflow: hidden;
    margin: 20px 0;
    i {
      display: block;
      float: left;
      padding: 10px;
      background-color: #880A16;
      font-size: 22px;
      color: #fff;
    }
    p {
      display: block;
      float: left;
      padding: 10px;
      margin: 0;
      background-color: #D2292C;
      line-height: 1;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 22px;
      color: #fff;
    }
  }
}

.sam-content-view-news {
  overflow: hidden;
  /*border-bottom: #9b9b9b solid thin;*/
  .item {
    margin: 0 0 30px;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
    .image {
      position: relative;
      display: block;
      height: 250px;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 14px;
      time {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px 25px;
        background-color: #D1292C;
        font-family: 'RobotoCondensed-Bold', sans-serif;
        font-size: 14px;
        color: #fff;
        z-index: 1;
      }
    }
    .title {
      display: block;
      padding: 20px 0;
      line-height: 1.2;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 20px;
      color: inherit;
    }
  }
}

.reviews_add_link {
  p {margin: 0; padding: 0; line-height: 1;}
  float: right;
  margin: 0 0 25px;
  padding: 20px 0;
  line-height: 1;
  /*border-bottom: #d5d5d5 solid thin;*/
  text-transform: uppercase;
  font-family: RobotoCondensed-Bold,sans-serif;
  font-size: 18px;
  color: #222;
  cursor: pointer;
  &:hover { color: #D1292C; }
}

.content-reviews-view {
  form { display: none; }
  overflow: hidden;
  /*border-bottom: #9b9b9b solid thin;*/
  .item {
    margin: 0 0 30px;
    text-align: center;
    font-family: 'Roboto-Regular', sans-serif;
    font-size: 14px;
    time {
      display: block;
      margin: 0 0 12px;
      line-height: 1;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 14px;
    }
    .title {
      display: block;
      margin: 0 0 8px;
      line-height: 1;
      font-family: 'RobotoCondensed-Bold', sans-serif;
      font-size: 18px;
      color: #D1292C;;
    }
    .post {
      p {margin: 0; padding: 0; line-height: inherit; }
      a { color: #D1292C; &:hover { color: #2d5d91; text-decoration: none; } }
      overflow: hidden;
      padding: 15px;
      line-height: 1.2;
      border: #D4D4D4 solid thin;
      font-family: 'Roboto-Regular', sans-serif;
      font-size: 14px;
    }
  }
}

@media all and (max-width:768px) {
  header {
    .sam-sitename { .sam-logo h1 { font-size: 34px; }
      .sam-slogan {
        top: 60px;
        left: 255px;
        letter-spacing: 0;
        font-size: 8px;
      }
    }
    .sam-contacts {
      .sam-phones { font-size: 18px; }
      .sam-address { font-size: 14px; }
    }
  }
  .navbar { font-size: 14px; }
  .sam-row-1 {
    height: 275px;
    .catalog-top {
      .catalog-top-item {
        padding: 40px 0 0 0;
        .box-image { height: 155px;}
        p {
          margin: 15px 0 0 0;
          font-size: 16px;
          i { font-size: 35px; }
        }
      }
    }
  }
  .sam-row-6 {
    .sam-row-box {
      background-image: none;
      .left, .right {
        overflow: hidden;
        background-image: url('../images/spec-auto-market/bg-reviews-instagram.jpg');

        background-repeat: no-repeat;
      }
    }
    .left {
      background-position: left top;
    }
    .right {
      background-position: right top;
    }
  }
}
@media all and (max-width:480px) {
  header {
    .frame-box {
      width: 280px;
      margin: 0 auto;
    }
    .sam-sitename {
      padding: 30px 0 0;
      .sam-logo h1 { font-size: 28px; }
      .sam-slogan {
        top: 55px;
        left: 210px;
        letter-spacing: 0;
        font-size: 8px;
      }
    }
    .sam-contacts {
      text-align: center;
      .sam-phones { font-size: 16px; }
      .sam-address { font-size: 14px; }
    }
  }
  .sam-slider-main {
    .slide-item {
      height: 290px;
      .slide-caption {
        height: 290px;
        background-image: url("../images/spec-auto-market/bg-slider.png");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: 80% 100%;
        .box-content {
          height: 290px;
          .title {
            width: auto;
            height: 100px;
            overflow: hidden;
            bottom: 10px;
            right: 0px;
            line-height: 1.2;
            font-size: 18px;
            width: 60%;
          }
        }
      }
    }
    .slider-control {
      &.arrow-left { padding: 5px 5px 3px; }
      &.arrow-right { padding: 5px 5px 3px; }
    }
  }
  .navbar { min-height: auto; font-size: 14px; }
  .sam-row-1 {
    height: auto;
    background-image: none;
    background-color: #272727;
    .catalog-top {
      padding: 10px 0;
      .catalog-top-item {
        padding: 10px 0;
        .box-image { height: 155px;}
        p {
          margin: 15px 0 0 0;
          font-size: 16px;
          i { font-size: 35px; }
        }
      }
    }
  }
  .sam-row-catalog {
    padding: 20px 0 0;
  }
  .sam-slider-catalog {
    .header {
      .more {
        float: none;
        clear: both;
        margin: 5px 0 0 0;
        text-align: right;
      }
    }
  }
  .sam-row-5 {
    .news {
      .box-content {
        margin: 20px 0 0 0;
        .item { margin: 0 0 20px; }
        .caption {
          height: auto;
          .title, .description { p { margin: 0; } }
          .title { margin: 5px 0 0; }
        }
      }
      .more { float: left; }
    }
  }
  .sam-row-6 { .instagram { .box-content { padding: 20px 0 0; } }
    .reviews { margin: 0 0 40px; .box-content { padding: 20px 0 0 0; } }
  }

  .sam-row-7 {
    .banner {
      float: none;
      width: 100%;
      padding: 15px;
    }
  }
  .sam-row-8 {
    height: 700px;
    .contacts-bg {
      width: 100%;
      background-position: left top;
      background-size: 200% 100%;
    }
    .map { width: 100%; height: 700px; }
  }
  footer {
    background-color: #272727;
    min-height: 70px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    a { color: #fff; &:hover { text-decoration: none;}}
    .copy { line-height: 30px; p { margin: 0; } }
    .outs {
      text-align: center;
      p { float: left; padding: 0 10px; margin: 0; line-height: 1; }
      text-align: right; padding: 20px 0;
    }
  }
}

@media all and (min-width:1281px) {
  .sam-slider-main { .slide-item { .slide-caption { background-position: right top; } } }
  .sam-row-3 { .sam-about { background-position: left center; } }
  .sam-row-8 { background-position: left top, center top; }
}